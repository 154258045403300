import { AnyAction } from '@reduxjs/toolkit';

import * as actions from '../actions/controller-readers';

interface State {
  readers: any[];
}

const initialState: State = {
  readers: []
}

const controllerReadersReducer = (state = initialState, action: AnyAction) => {
  switch (action.type) {

    case actions.SET_READERS:
      return {
        ...state,
        readers: [...action.payload],
      }

    case actions.UPDATE_READER:
      const updatedReaders = state.readers.map(reader => {
        return reader.serial_number === action.payload.serial_number ? action.payload : reader
      });

      return {
        ...state,
        readers: updatedReaders,
      }

    default:
      return state;
  }
}

export default controllerReadersReducer;
