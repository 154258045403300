import { Lockdown } from 'interfaces/lockdown.interface';

import * as actions from '../actions/sites-lockdown';

interface State {
  lockdown: Lockdown[];
}

const initialState: State = {
  lockdown: []
};

type Action = { type: 'SET_LOCKDOWN', payload: Lockdown[] };

const sitesLockdownReducer = (
  state = initialState,
  action: Action,
) => {
  switch (action.type) {
    /**
     * Despite the name, it's an array...
     *
     * TODO: to be corrected
     */
    case actions.SET_LOCKDOWN:
      return {
        ...state,
        lockdown: [...action.payload]
      };

    default:
      return state;
  }
};

export default sitesLockdownReducer;
