import { lazy, Suspense } from 'react';

import { Navigate, Route, Routes } from 'react-router-dom';

import SplashScreen from '../components/SplashScreen/SplashScreen';

import PublicLayout from '../layout/PublicLayout/PublicLayout';

const Activate = lazy(() => import('views-public/Activate/Activate'));
const AddDealerRequest = lazy(() => import('views-public/AddDealerRequest/AddDealerRequest'));
const ForgotPassword = lazy(() => import('views-public/ForgotPassword/ForgotPassword'));
const LoginScreen = lazy(() => import('views-public/LoginScreen/LoginScreen'));
const PreRegister = lazy(() => import('views-public/PreRegister/PreRegister'));

const PublicRoutes = () => {
  return (
    <Routes>
      <Route element={<PublicLayout />}>
        <Route
          path="/activate_account"
          element={
            <Suspense fallback={<SplashScreen />}>
              <Activate />
            </Suspense>
          }
        />
        <Route
          path="/dealer_request"
          element={
            <Suspense fallback={<SplashScreen />}>
              <AddDealerRequest />
            </Suspense>
          }
        />
        <Route
          path="/forgot_password"
          element={
            <Suspense fallback={<SplashScreen />}>
              <ForgotPassword />
            </Suspense>
          }
        />
        <Route
          path="/login"
          element={
            <Suspense fallback={<SplashScreen />}>
              <LoginScreen />
            </Suspense>
          }
        />
        <Route
          path="/preregistration"
          element={
            <Suspense fallback={<SplashScreen />}>
              <PreRegister />
            </Suspense>
          }
        />

        {/* Page Not Found */}
        <Route path="*" element={<Navigate to="/login" />} />
      </Route>
    </Routes>
  );
};

export { PublicRoutes };
