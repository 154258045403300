import React, { FC, ReactNode } from 'react';

// Mui
import { Box, Stack } from '@mui/material';
import { yellow } from '@mui/material/colors';
import ErrorOutlineOutlinedIcon from '@mui/icons-material/ErrorOutlineOutlined';

// Project imports
import Modal from '../Modal/Modal';

import Button from '../Button/Button';
import SpinIcon from '../SpinIcon/SpinIcon';

const ConfirmationModal: FC<{
  open: boolean;
  onClose: () => void;
  onConfirm: (str?: any) => void;
  onCancel?: () => void;
  message: string;
  message2?: string;
  cancelBtnTxt: string;
  submitBtnTxt: string;
  icon?: boolean;
  customIcon?: ReactNode;
  displayConfirm?: boolean;
  alternativeTitle?: string;
  errorIcon?: boolean;
  refreshIcon?: boolean;
  errorMessage?: string;
  infoMessage?: string;
  color?: string;
  element?: React.ReactNode;
  width?: string;
  disabled?: boolean;
}> = ({
  open,
  onClose,
  onConfirm,
  onCancel,
  message,
  message2,
  color,
  cancelBtnTxt,
  submitBtnTxt,
  icon,
  customIcon,
  displayConfirm = true,
  alternativeTitle,
  errorIcon,
  refreshIcon,
  errorMessage,
  infoMessage,
  element,
  width,
  disabled
}) => {
  return (
    <Modal
      width={width}
      open={open}
      onClose={onClose}
      title={alternativeTitle ? alternativeTitle : 'Confirmation'}
    >
      <Box padding={4} paddingTop={0}>
        <Stack direction={'column'} gap={3.5} alignContent={'center'}>
          {customIcon && <Box alignSelf="center">{customIcon}</Box>}
          {icon && (
            <>
              {refreshIcon ? (
                <SpinIcon
                  sx={{
                    color: 'green',
                    alignSelf: 'center',
                    fontSize: '100px',
                    animation: 'colorChange 5s ease forwards',
                    '@keyframes colorChange': {
                      '0%': {
                        color: 'green'
                      },
                      '100%': {
                        color: yellow[500]
                      }
                    }
                  }}
                />
              ) : (
                <ErrorOutlineOutlinedIcon
                  sx={{
                    fontSize: '100px',
                    color: errorIcon ? 'red' : yellow[700],
                    alignSelf: 'center'
                  }}
                />
              )}
            </>
          )}
          <span style={{ textAlign: 'center' }}>
            <div dangerouslySetInnerHTML={{ __html: message }}></div>
            {message2 && (
              <>
                <span style={{ color: color ? color : '' }}>{message2}</span>
                <span>?</span>
              </>
            )}
            {errorMessage && (
              <span style={{ color: 'red' }}>
                <br></br>
                {errorMessage}
              </span>
            )}
            {infoMessage && (
              <span>
                <br></br>
                {infoMessage}
              </span>
            )}
          </span>
          {element}
        </Stack>
        <Stack
          marginTop={5}
          direction={'row'}
          justifyContent={'center'}
          gap={2}
        >
          <Button
            color="gray"
            onClick={onCancel || onClose}
            id="confirmation-modal-cancel-btn"
          >
            {cancelBtnTxt}
          </Button>
          {displayConfirm && (
            <Button
              color="primary"
              disabled={disabled}
              onClick={onConfirm}
              id="confirmation-modal-confirm-btn"
            >
              {submitBtnTxt}
            </Button>
          )}
        </Stack>
      </Box>
    </Modal>
  );
};

export default ConfirmationModal;
