/*
 * Description: Redux store for the support session of CDVI Support Tool
 * Created by: Diego Rincón <drincon@binwus.com>
 * Created at: 2024-06-18
 */

import { AnyAction } from '@reduxjs/toolkit';
import { OpenSession } from 'interfaces/supportTool.interface';
import { SET_CURRENT_SESSION } from '../actions/support-session';

// Initial state
const initialState = {
  session: undefined
};

// Reducer
const supportSessionReducer = (
  state = initialState as {
    session: OpenSession | undefined;
  },
  action: AnyAction
) => {
  switch (action.type) {
    // Set User's data
    case SET_CURRENT_SESSION:
      const { session } = action;
      return {
        ...state,
        session: {
          ...session
        }
      };

    default:
      return state;
  }
};

export default supportSessionReducer;
