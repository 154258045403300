import React from 'react';
import AutorenewIcon from '@mui/icons-material/Autorenew';
import { SvgIconProps } from '@mui/material/SvgIcon';

type MuiIconComponent = React.ComponentType<SvgIconProps> & {
  muiName: string;
};

type IconComponentType = MuiIconComponent | undefined;

interface SpinIconProps extends Omit<SvgIconProps, 'component'> {
  IconComponent?: IconComponentType;
  spinning?: boolean;
}

const SpinIcon: React.FC<SpinIconProps> = ({
  IconComponent = AutorenewIcon,
  sx,
  spinning = true,
  ...props
}) => {
  const spinAnimation = spinning ? 'spin 1.7s linear infinite' : '';
  const userAnimation = (sx as any)?.animation;
  const animationValue = `${spinAnimation}${
    userAnimation ? `, ${userAnimation}` : ''
  }`;

  return (
    <IconComponent
      sx={{
        '@keyframes spin': {
          '0%': {
            transform: 'rotate(0deg)'
          },
          '100%': {
            transform: 'rotate(360deg)'
          }
        },
        ...sx,
        animation: animationValue
      }}
      {...props}
    />
  );
};

export default SpinIcon;
