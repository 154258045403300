import { useEffect, useState } from "react";

import { Theme, useMediaQuery } from "@mui/material";

export const useDrawerOpening = () => {
  const min1200 = useMediaQuery<Theme>(theme => theme.breakpoints.up('lg'));

  const [open, setOpen] = useState(() => {
    return min1200;
  });

  useEffect(() => {
    if (!min1200 && open) {
      setOpen(false);
    }
  }, [min1200]);

  const toggle = () => {
    setOpen(prev => !prev);
  }

  return { open, toggle };
}
