import { Box, Typography } from "@mui/material";

import { useTranslation } from "react-i18next"

import { getLangData } from "helpers/getLangData";

interface Props {
  language?: string;
}

/**
 * Displays the flag and the name of the given language.
 *
 * If no language is given, the current language of the site is used.
 */
function SiteLanguage({ language }: Props) {
  const { i18n } = useTranslation();

  const langData = getLangData(language ?? i18n.language);

  return (
    <Box
      display="flex"
      alignItems="center"
      justifyContent="space-between"
      columnGap={1.5}
    >
      <img
        width={15}
        src={langData!.flag}
        alt={langData!.nativeName}
      />

      <Typography component="div" textAlign="center">
        {langData!.nativeName}
      </Typography>
    </Box>
  )
}

export { SiteLanguage };
