export const lightPalette = (theme: any) => {
  return {
    mode: 'light',
    common: {
      black: theme.colors?.darkPaper
    },
    primary: {
      light: theme.colors?.primaryLight,
      main: theme.colors?.primaryMain,
      dark: theme.colors?.primaryDark,
      gradient: `linear-gradient(0deg, #3630b9 0%,  ${theme.colors?.primaryDark} 100%)`,
      200: theme.colors?.primary200,
      800: theme.colors?.primary800,
      container: '#FFFFFF'
    },
    secondary: {
      light: '#FFFFFF',
      main: '#003d6d',
      dark: theme.colors?.secondaryDark,
      gradient: '#FFFFFF',
      200: theme.colors?.secondary200,
      800: theme.colors?.secondary800
    },
    error: {
      light: theme.colors?.errorLight,
      main: theme.colors?.errorMain,
      dark: theme.colors?.errorDark
    },
    warning: {
      light: theme.colors?.warningLight,
      main: theme.colors?.warningMain,
      dark: theme.colors?.warningDark
    },
    success: {
      light: theme.colors?.successLight,
      200: theme.colors?.success200,
      main: theme.colors?.successMain,
      dark: theme.colors?.successDark
    },
    text: {
      primary: theme.darkTextPrimary,
      secondary: theme.darkTextSecondary,
      title: '#003d6d',
      dark: theme.textDark,
      hint: theme.colors?.grey100
    },
    background: {
      paper: theme.paper,
      default: theme.backgroundDefault
    },
    input: {
      background: '#F2F2F2',
      disabled: '#F5F5F5',
      timezone: '#F5F5F5',
      searchbar: '#F5F5F5'
    },
    button: {
      background: '#2196f3',
      disabled: '#D3D3D3',
      transparent: theme.colors?.primaryMain
    },
    container: {
      background: '#003d6d',
      disabled: theme.colors?.grey200,
      hover: '#2195ff0d',
      body: '#FFFFFF',
      card: '#FFFFFF',
      border: '#D3D3D3',
      dark: '#e4e4e4',
      label: '#c5c5c5'
    },
    table: {
      header: '#FFFFFF',
      darkBg: '#F5F5F5'
    }
  };
};
