const navigationMenu = {
  PERMISSION_OPTION: 'Permisos',
  MENU_OPTION: 'Menú',
  USERS: 'Usuarios',
  ROLES: 'Roles',
  TESTINGTOOL_OPTION: 'Herramienta de prueba',
  USER_MANAGEMENT: 'Gestión de usuarios',
  SECURITY: 'Seguridad',
  TESTINGTOOLA: 'Herramienta de prueba del controlador',
  Dealer: 'Distribuidor',
  DEALER: 'Distribuidor',
  'Dealer Request': 'Peticiones de Dealer',
  DEALER_OPTION: 'Distribuidor',
  Trial: 'Prueba',
  Organization: 'Cuenta',
  ORGANIZATION_OPTION: 'Cuentas',
  SITE_OPTION: 'Sitios',
  SITE_INFO: 'Información Sitio',
  CONTROLLER_OPTION: 'Controlador',
  ORGANIZATION: 'Cuenta',
  DOOR_OPTION: 'Puerta',
  USERORG_OPTION: 'Usuarios',
  HOLIDAY_OPTION: 'Vacación',
  SCHEDULE_OPTION: 'Calendario',
  ACCESSLEVEL_OPTION: 'Nivel de acceso',
  GLOBALSETTINGS_OPTION: 'Ajustes globales',
  HOME_OPTION: 'Inicio',
  CARD_OPTION: 'Credencial',
  CONTROLLER_ADM: 'Controlador',
  EVENT_OPTION: 'Eventos',
  ACCOUNT_INFO: 'Info de cuenta',
  ORG_INFO_PROFILE: 'Información de cuenta',
  TASK_OPTION: 'Tareas',
  DEALER_INFO: 'Información Distribuidor',
  RESOURCES: 'Recursos',
  DEALER_REQUESTS: 'Solicitudes de Distribuidores',
  LOCKDOWN_OPTION: 'Aislamiento',
  FLOOR_PLAN_OPTION: 'Plano',
  OPTION_SET_PRICE: 'Precios',
  HARDWARE: 'Hardware',
  SETTINGS: 'Ajustes',
  Reports: 'Reportes',
  Input: 'Entrada de controladores',
  Output: 'Salida de controladores',
  firmware_manager: 'Administrador de Firmware',
  ORGUSERS_REPORT: 'Reporte de Usuarios',
  ORGCARDS_REPORT: 'Reporte de Credenciales',
  Events: 'Reporte de Eventos',
  'CDVI TECH': 'CDVI TECNOLOGIAS',
  'CDVI ADMIN': 'CDVI ADMINISTRACION',
  INTERLOCK_OPTION: 'Interbloqueos',
  CDVI_SUPPORT: 'CDVI SOPORTE',
  CDVI_SUPPORT_OPEN_SESSIONS: 'Sesiones abiertas',
  CDVI_SUPPORT_NEW_SESSION: 'Nueva sesión',
  CDVI_SUPPORT_CURRENT_SESSION: 'Sesión actual',
  CDVI_SUPPORT_DIAGNOSE: 'Diagnóstico Controlador',
  NOTIFICATIONS: 'Notificaciones',
  config: {
    open_settings: 'Configuración abierta',
    profile: 'Perfil',
    dealer_info: 'Información del distribuidor',
    logout: 'Cerrar sesión',
    change_password: 'Cambia la contraseña',
    english: 'English',
    spanish: 'Español',
    french: 'Français',
    switch_profile: 'Cambiar perfil',
    language: 'Lenguaje'
  },
  switch: {
    welcome: 'Bienvenido, {{name}}',
    please_select: 'Por favor, seleccione',
    name: 'Nombre',
    last_login: 'Último inicio de sesión',
    actions: 'Acciones',
    login: 'Iniciar sesión',
    active_profile: 'Perfil activo',
    as_dealer: 'Como distribuidor',
    as_organization: 'Como organización',
    as_system: 'Como sistema'
  }
};

export default navigationMenu;
