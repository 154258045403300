import { useEffect, useState, useCallback } from 'react';
import { useSelector } from '../../../store';
import { Typography } from '@mui/material';
import dayjs, { Dayjs } from 'dayjs';

const Clock: React.FC = () => {
  const selectedSite = useSelector((store) => store.selectedSite.site);
  const [currentTime, setCurrentTime] = useState<Dayjs | null>(null);

  const updateTime = useCallback(() => {
    if (selectedSite?.timezone) {
      setCurrentTime(dayjs().tz(selectedSite.timezone));
    }
  }, [selectedSite?.timezone]);

  useEffect(() => {
    updateTime(); // Initial update
    const intervalId = setInterval(updateTime, 1000);
    return () => clearInterval(intervalId);
  }, [updateTime]);

  if (!selectedSite?.timezone || !currentTime) {
    return null;
  }

  return (
    <Typography align="right">
      {currentTime.format('MM/DD/YYYY')}
      <br />
      {currentTime.format('h:mm:ss A')}
    </Typography>
  );
};

export default Clock;
