import user from 'interfaces/user.interface';
import routes from '../../../routes/routes';
import { useDispatch, useSelector } from '../../../store';
import Avatar from '../../../components/Avatar/Avatar';
import placeholder from '../../../assets/images/media/avatars/logo_placeholder.png';
import { Box, IconButton, Stack, Typography } from '@mui/material';
import { useLocation, useNavigate } from 'react-router-dom';
import { site } from 'interfaces/site.interface';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { useTranslation } from 'react-i18next';
import { useTheme } from '@mui/system';
import { OpenSession } from 'interfaces/supportTool.interface';
import { setUser } from '../../../store/actions/user';
import ClearIcon from '@mui/icons-material/Clear';

const categoryOptions = [
  {
    routes: [routes.report_cards, routes.report_events, routes.report_users],
    view: 'report',
    label: 'Reports'
  },
  {
    routes: [
      routes.controller_list,
      routes.output_list,
      routes.input_list,
      routes.firmware_manager
    ],
    view: 'hardware',
    label: 'HARDWARE'
  },
  {
    routes: [
      routes.holiday_list,
      routes.schedule_list,
      routes.access_level,
      routes.access_level_manager,
      routes.global_settings,
      routes.floor_plan,
      routes.interlocks_list,
      routes.lockdown
    ],
    view: 'setting',
    label: 'SETTINGS'
  }
];

const navigationOptions = [
  // { routeName: "ACCOUNT_INFO", url: "/organization_info" },
  { routeName: 'SITE_OPTION', url: '/site/list' },
  // { routeName: "SITE_INFO", url: "/site/dashboard" },
  { routeName: 'DOOR_OPTION', url: '/door' },
  {
    routeName: 'USERORG_OPTION',
    url: '/user_org',
    navigateTo: '/user_org/list'
  },
  { routeName: 'CARD_OPTION', url: '/card/list' },
  { routeName: 'ORGUSERS_REPORT', url: '/report/users' },
  { routeName: 'ORGCARDS_REPORT', url: '/report/cards' },
  { routeName: 'Events', url: '/report/events' },
  { routeName: 'HOLIDAY_OPTION', url: '/holiday' },
  { routeName: 'SCHEDULE_OPTION', url: '/schedule' },
  {
    routeName: 'ACCESSLEVEL_OPTION',
    url: '/access_level',
    navigateTo: '/access_level/list'
  },
  { routeName: 'GLOBALSETTINGS_OPTION', url: '/global_settings' },
  { routeName: 'LOCKDOWN_OPTION', url: '/lockdown' },
  { routeName: 'FLOOR_PLAN_OPTION', url: '/floor_plan' },
  { routeName: 'INTERLOCK_OPTION', url: '/interlock' },
  { routeName: 'CONTROLLER_OPTION', url: '/controller' },
  { routeName: 'Input', url: '/input' },
  { routeName: 'Output', url: '/output' },
  { routeName: 'Firmware Manager', url: '/firmware_manager' },
  { routeName: 'EVENT_OPTION', url: '/event/list' },
  { routeName: 'NOTIFICATIONS', url: '/site/notifications' }
];

const SelectionNav = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const theme = useTheme();
  const { pathname, search } = useLocation();
  const user: user = useSelector((store) => store.user.user);
  const selectedSite: site = useSelector((store) => store.selectedSite.site);
  const session: OpenSession = useSelector(
    (store) => store.supportSession.session
  );

  const isSystemUser = user.profile_type === 'system' && !user.is_support;

  const isEmpty = (obj: object | undefined) =>
    obj && typeof obj === 'object' && Object.keys(obj).length === 0;

  const isFloorPlan = () =>
    pathname.includes(routes.floor_plan_view.split(':')[0]);

  const clearUser = () => {
    if (!isSystemUser) return;
    dispatch(setUser({ ...user, selectedDealer: null, selectedOrg: null }));
  };

  const arrowElement = () => (
    <Box alignSelf="center">
      <ArrowForwardIosIcon
        fontSize="small"
        sx={{ color: theme.palette.text.secondary }}
      />
    </Box>
  );

  const buttonElement = (
    route: string,
    text: string,
    hasEmphasis: boolean = false
  ) => (
    <Box mt={0.5}>
      <IconButton
        disabled={isFloorPlan()}
        onClick={() => !!route && navigate(route)}
      >
        <Typography sx={{
          color: 'white',
          fontWeight: hasEmphasis ? 'bold' : '',
          fontSize: '16px'
        }}>
          {text}
        </Typography>
      </IconButton>
    </Box>
  );

  const goTo = () => {
    switch (user.profile_type) {
      case 'organization':
        return user.selectedOrg?.multi_site && selectedSite?.site
          ? routes.site_dashboard
          : routes.home;

      default:
        return user.selectedOrg?.multi_site && selectedSite?.site
          ? routes.site_dashboard
          : routes.organization_info;
    }
  };

  return (
    <Stack direction={'row'} gap={1} alignItems="center">
      {user.selectedDealer && (
        <>
          {!isFloorPlan() && (
            <Box
              sx={{
                position: 'relative',
                '&:hover .clear-icon-button': {
                  opacity: 1
                }
              }}
            >
              <Avatar
                className="transparent-avatar"
                variant="rounded"
                sx={{ width: 40, height: 40 }}
                src={String(user.selectedDealer?.image)}
              >
                <img src={placeholder} alt="Dealer avatar" />
              </Avatar>
              {isSystemUser && (
                <IconButton
                  className="clear-icon-button"
                  size="small"
                  onClick={clearUser}
                  sx={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    backgroundColor: 'background.paper',
                    opacity: 0,
                    transition: 'opacity 0.2s',
                    '&:hover': { backgroundColor: 'background.paper' }
                  }}
                >
                  <ClearIcon fontSize="small" color="action" />
                </IconButton>
              )}
            </Box>
          )}
          {buttonElement(
            isSystemUser ? routes.organization_list : routes.home,
            user.selectedDealer?.name
          )}
        </>
      )}
      {session && (
        <>
          {buttonElement(
            routes.support_current_session,
            t('supportTool.support_session')
          )}
        </>
      )}
      {user.selectedOrg && (
        <>
          {(user.selectedDealer || session) && arrowElement()}
          {buttonElement(routes.organization_info, user.selectedOrg.name)}
        </>
      )}
      {user.selectedOrg?.multi_site && (
        <>
          {user.selectedOrg && selectedSite?.site && arrowElement()}
          {selectedSite?.site &&
            buttonElement(routes.site_dashboard, selectedSite.name)}
        </>
      )}
      {categoryOptions.map(
        ({ routes, view, label }) =>
          (routes.some((route) => pathname.includes(route)) ||
            search === `?view=${view}`) && (
            <>
              {arrowElement()}
              {buttonElement(
                `${goTo()}?view=${
                  isEmpty(selectedSite) && view !== 'report' ? '' : view
                }`,
                t(label),
                search === `?view=${view}`
              )}
            </>
          )
      )}
      {navigationOptions.map(
        (option) =>
          pathname.includes(option.url) && (
            <>
              {arrowElement()}
              {buttonElement(
                option.navigateTo ?? '',
                t(`${option.routeName}`),
                true
              )}
            </>
          )
      )}
    </Stack>
  );
};

export default SelectionNav;
