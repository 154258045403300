import dayjs from 'dayjs';

type DateType = dayjs.ConfigType;

/**
 * Parses an ISO string to a Date object and sets the time components (hours, minutes, seconds).
 *
 * @param s - The ISO string to be parsed.
 * @returns A Date object with the time components set.
 */
export const parseISOStringToDate = (s: any) => {
  const hours = s.split('T')[1].split(':')[0];
  const minutes = s.split('T')[1].split(':')[1];
  const seconds = s.split('T')[1].split(':')[2].split('.')[0];

  const date = new Date(Date.parse(s));

  date.setHours(hours);
  date.setMinutes(minutes);
  date.setSeconds(seconds);

  return date;
};

/**
 * Formats a given date into a simple `MM/DD/YYYY` string format.
 */
export const dateSimpleFormat = (date: DateType) => {
  if (!date) return '';
  return dayjs(date).utc().format('MM/DD/YYYY');
};

/**
 * Formats a given date into a specified string format or `MM/DD/YYYY hh:mm:ss a` if not provided.
 */
export const dateFormat = (
  date: DateType,
  options?: {
    format?: string;
    keepLocalTime?: boolean;
  }
) => {
  if (!date) return '';
  return dayjs(date)
    .utc(Boolean(options?.keepLocalTime))
    .format(options?.format || 'MM/DD/YYYY hh:mm:ss a');
};

/**
 * Converts a given date to a UTC ISO string.
 */
export const parseToUtcIsoString = (date: Date | string | null) => {
  if (!date) return null;
  return dayjs(date).utc().toISOString();
};
