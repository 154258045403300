import flagEn from './../assets/images/media/flags/united-states.svg';
import flagEs from './../assets/images/media/flags/spain.svg';
import flagFr from './../assets/images/media/flags/france.svg';

type Language = 'en' | 'es' | 'fr';

type LangData = {
  flag: string;
  nativeName: string;
};

const data: Record<Language, LangData> = {
  en: { flag: flagEn, nativeName: 'English' },
  es: { flag: flagEs, nativeName: 'Español' },
  fr: { flag: flagFr, nativeName: 'Français' },
};

export const getLangData = (language: string): LangData | null => {
  if (data.hasOwnProperty(language)) {
    const element = data[language as Language];

    return {
      flag: element.flag,
      nativeName: element.nativeName,
    }
  }

  return null;
}
