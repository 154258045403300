import axios, { AxiosResponse } from 'axios';
import constant from './constants';
import { sessionWindowAxiosInterceptor } from '../utils/hooks/useSessionWindow';
import { store } from '../store';
import { removeUser } from '../store/actions/user';

let oauthToken: string;

axios.defaults.baseURL =
  !process.env.NODE_ENV || process.env.NODE_ENV === 'development'
    ? constant.DEV_URL
    : constant.PROD_URL;
axios.defaults.withCredentials = true;

/*
 * Support Tool
 * Description: Interceptor for closing the support session windows on error
*/
axios.interceptors.response.use(
  sessionWindowAxiosInterceptor.onFulfilled,
  sessionWindowAxiosInterceptor.onRejected
);

// Interceptor for redirecting to login page on Unauthorized error
axios.interceptors.response.use(
  (response) => response,
  (error) => {
    if (
      error.response?.data?.status === 401 &&
      error.response?.data?.code === 'S0003'
    ) {
      store.dispatch(removeUser());
    }
    return Promise.reject(error);
  }
);

// AbortController
const abortController = new AbortController();

const setOauthToken = () => {
  axios.defaults.headers.common['authorization'] = `Bearer ${oauthToken}`;
  axios.defaults.headers.common['app-authorization'] = `Bearer ${oauthToken}`;
};

const setAuthToken = async (): Promise<any> => {
  if (!oauthToken) {
    await postOauthToken();
  }
  const token = store.getState().user.token;
  if (token) {
    axios.defaults.headers.common['authorization'] = `Bearer ${token}`;
    axios.defaults.headers.common['app-authorization'] = `Bearer ${oauthToken}`;
  }
};

export const setSdtToken = (token: string) => {
  axios.defaults.headers.common['x-cdvi-sdt-token'] = `Bearer ${token}`;
}

export const setEnvironmentBaseURL = () => {
  const environmentBaseURL = localStorage.getItem('ENVIRONMENT_BASE_URL');
  axios.defaults.baseURL = environmentBaseURL
    ? environmentBaseURL
    : constant.PROD_URL;
  axios.defaults.withCredentials = true;
};

// Oauth token
export const postOauthToken = async (): Promise<any> => {
  const body = {
    client_id: constant.client_id,
    client_secret: constant.client_secret,
    audience: constant.audience,
    grant_type: constant.grant_type
  };
  try {
    const { data } = await axios.post(constant.oauthTokenEP, body, {
      withCredentials: true
    });
    oauthToken = data.access_token;
    setOauthToken();
  } catch (error: any) {
    console.error(
      constant.oauthTokenEP,
      error.response.data?.message || error.message
    );
  }
};

// Authenticate
export const postOauthAuthenticate = async (
  username: string,
  password: string
) => {
  const body = {
    username: username,
    password: password
  };
  try {
    const { data }: any = await axios.post(
      constant.oauthAuthenticateEP,
      body
    );

    return data;
  } catch (error: any) {
    console.error(
      constant.oauthAuthenticateEP,
      error.response.data?.message || error.message
    );
    return error.response.data;
  }
};

// OTP unknown login
export const postOtpLogin = async (
  email: string,
  otp: string
) => {
  const body = {
    email: email,
    otp: otp
  };
  const { data }: any = await axios.post(
    constant.oauthOTPVerifyLoginEP,
    body
  );

  return data;
};

// OTP
export const postOtp = async (email: string, forgot: boolean = true) => {
  const body = {
    email: email
  };
  const { data }: any = await axios.post(
    constant.oauthOTPEP(forgot ? 'forgot' : ''),
    body
  );
  return data;
};

// OTP Email
export const postOtpEmail = async (email: string) => {
  const body = {
    email: email
  };
  const { data }: any = await axios.post(
    constant.oauthOTPEP('change-email'),
    body
  );
  return data;
};

// OTP Verify
export const postOtpVerify = async (email: string, otp: number) => {
  const body = {
    email: email,
    otp: otp
  };
  const { data }: any = await axios.post(
    constant.oauthOTPVerifyEP,
    body
  );
  return data;
};

// OTP Change Password
export const postOtpChangePassword = async (
  email: string,
  otp: number,
  newPassword: string
) => {
  const body = {
    email: email,
    otp: otp,
    newPassword: newPassword
  };
  const { data }: any = await axios.post(
    constant.oauthOTPchangePasswordEP,
    body
  );
  return data;
};

// OTP Change Email
export const postOtpChangeEmail = async (
  email: string,
  newEmail: string,
  otp: number
) => {
  const body = {
    email: email,
    newEmail: newEmail,
    otp: otp
  };
  const { data, headers }: any = await axios.post(
    constant.oauthOTPChangeEmailEP,
    body
  );
  console.log(data, headers);
  return data;
};

export const postUserActivate = async (
  password: any,
  email: any,
  otp: any,
  dealer: any
): Promise<any> => {
  const body = {
    password: password,
    email: email,
    otp: otp,
    dealer: dealer
  };
  await setAuthToken();
  const { data }: AxiosResponse = await axios.post(
    constant.oauthActivateUserEP,
    body
  );
  return data;
};

export const changeUserSession = async (profile: string) => {
  await setAuthToken();
  const { data } = await axios.post(constant.userChangeSessionEP, {
    profile
  });
  return data;
};

//Verify Password
export const postVerifyPassword = async (
  password: string
) => {
  const body = {
    password: password
  };
  await setAuthToken();
  const { data } = await axios.post(
    `${constant.oauthVerifyPassword}`,
    body
  );
  return data;
};

// Users
export const getSecurityUsers = async (filter?: string): Promise<any> => {
  try {
    await setAuthToken();
    const url = `${constant.securityUserEP}${
      filter ? `?filter=${filter}` : ''
    }`;
    const { data }: any = await axios.get(url);
    return data;
  } catch (error: any) {
    console.error(
      constant.securityUserEP,
      error.response.data?.message || error.message
    );
  }
};

export const postSecurityUser = async (body: any) => {
  try {
    await setAuthToken();
    const response: AxiosResponse = await axios.post(
      constant.securityUserEP,
      body
    );
    return response;
  } catch (error: any) {
    console.error(
      'POST',
      constant.securityUserEP,
      error.response.data?.message || error.message
    );
    return error.response.data;
  }
};

export const deleteSecurityUser = async (user: string) => {
  try {
    await setAuthToken();
    const response: AxiosResponse = await axios.delete(
      constant.securityUserParamEP(user)
    );
    return response;
  } catch (error: any) {
    console.error(
      'POST',
      constant.securityUserEP,
      error.response.data?.message || error.message
    );
    return error.response.data;
  }
};

export const getSecurityUserByID = async (user: string) => {
  try {
    await setAuthToken();
    const { data }: any = await axios.get(`${constant.securityUserEP}/${user}`);
    return data;
  } catch (error: any) {
    console.error(
      'GET',
      `${constant.securityUserEP}/${user}`,
      error.response.data
    );
  }
};

export const putSecurityUser = async (user: string, userData: any) => {
  try {
    await setAuthToken();
    const body = {
      firstName: userData.firstName || '',
      lastName: userData.lastName || '',
      email: userData.email || '',
      role: userData.role || '',
      phone: userData.phone || '',
      username: userData.username || '',
      password: userData.password || ''
    };
    const response: AxiosResponse = await axios.put(
      `${constant.securityUserEP}/${user}`,
      body
    );
    return response;
  } catch (error: any) {
    console.error(
      'PUT',
      `${constant.securityUserEP}/${user}`,
      error.response.data
    );
  }
};

export const getUserProfiles = async (userId: string): Promise<any> => {
  try {
    const { data }: AxiosResponse = await axios.get(
      constant.userProfilesEP(userId)
    );
    return data;
  } catch (error: any) {
    console.error(
      'GET',
      constant.userProfilesEP,
      error.response.data?.message || error.message
    );
  }
};

export const getUserDashboard = async (dealer?: string): Promise<any> => {
  await setAuthToken();
  const { data } = await axios.get(
    `${constant.userDashboardEP}${dealer ? `?dealer=${dealer}` : ''}`
  );
  return data;
};

export const getUserDashboardEvents = async (
  organization = ''
): Promise<any> => {
  await setAuthToken();
  const { data } = await axios.get(
    `${constant.userDashboardEventsEP}${
      organization ? `?organization=${organization}` : ''
    }`
  );
  return data;
};

export const getUserActivity = async (
  userId: string,
  from: string,
  to: string
): Promise<any> => {
  await setAuthToken();

  try {
    const { data }: AxiosResponse = await axios.get(
      constant.userActivityEP(userId, from, to)
    );
    return data;
  } catch (error: any) {
    console.error(
      'GET',
      constant.userActivityEP,
      error.response.data?.message || error.message
    );
  }
};

export const getUserImage = async (user: string): Promise<any> => {
  await setAuthToken();
  const { data } = await axios.get(constant.userImageEP(user));
  return data;
};

export const updateUserProfileRole = async (
  user: string,
  dealerOrg: string,
  body: any
): Promise<any> => {
  try {
    const { data }: AxiosResponse = await axios.put(
      constant.userProfileUpdateEP(user, dealerOrg),
      body
    );
    return data;
  } catch (error: any) {
    console.error(
      'PUT',
      constant.userProfileUpdateEP,
      error.response.data?.message || error.message
    );
  }
};

export const updateUserNames = async (firstName: string, lastName: string) => {
  const body = {
    firstName: firstName,
    lastName: lastName
  };
  try {
    await setAuthToken();
    const response = await axios.put(constant.userChangeNamesEP, body);
    return response;
  } catch (error: any) {
    console.error(
      'PUT',
      constant.userChangeNamesEP,
      error.response.data?.message || error.message
    );
  }
};

export const updateUserImage = async (image: any): Promise<any> => {
  const body = {
    logo: image
  };
  try {
    await setAuthToken();
    const response = await axios.put(constant.userChangeImageEP, body);
    return response;
  } catch (error: any) {
    console.error(
      'PUT',
      constant.userChangeImageEP,
      error.response.data?.message || error.message
    );
  }
};

export const updateDealerImage = async (
  image: any,
  dealer?: string
): Promise<any> => {
  const body = {
    logo: image
  };
  try {
    await setAuthToken();
    const url = `${constant.organizationProfileDealerImageEP}${
      dealer ? '?dealer=' + dealer : ''
    }`;
    const response = await axios.put(url, body);
    return response;
  } catch (error: any) {
    console.error(
      'PUT',
      constant.organizationProfileDealerImageEP,
      error.response.data?.message || error.message
    );
  }
};

export const deleteUserProfile = async (
  user: string,
  dealerOrg: string,
  profileType: string
): Promise<any> => {
  try {
    const { data }: AxiosResponse = await axios.delete(
      constant.userDeleteProfileEP(user, dealerOrg, profileType)
    );
    return data;
  } catch (error: any) {
    console.error(
      'DELETE',
      constant.userDeleteProfileEP,
      error.response.data?.message || error.message
    );
  }
};

export const putUserpasswrd = async (body: any) => {
  await setAuthToken();
  const { data } = await axios.put(constant.userChangepsswdEP, body);
  return data;
};

// Role
export const getSecurityRole = async (): Promise<any> => {
  await setAuthToken();
  const { data }: AxiosResponse = await axios.get(constant.securityRoleEP);
  return data;
};

export const getSecurityRoleById = async (role: string) => {
  try {
    await setAuthToken();
    const { data }: AxiosResponse = await axios.get(
      constant.securityRoleByIDEP(role)
    );
    return data;
  } catch (error: any) {
    console.error(
      'GET',
      constant.securityRoleByIDEP(role),
      error.response.data?.message || error.message
    );
  }
};

export const getSecurityRoleMenu = async (role: string) => {
  await setAuthToken();
  const { data }: AxiosResponse = await axios.get(
    constant.securityRoleMenuEP(role)
  );
  return data;
};

export const getRolebyPermission = async (permission: string) => {
  await setAuthToken();
  const { data } = await axios.get(constant.roleByPermissionEP(permission));
  return data;
};

export const getRolebyMenu = async (menu: string) => {
  await setAuthToken();
  const { data } = await axios.get(constant.roleByMenuEP(menu));
  return data;
};

export const getMenuById = async (menu: string) => {
  await setAuthToken();
  const { data } = await axios.get(constant.menuByIDEP(menu));
  return data;
};

export const postMenu = async (payload: any) => {
  await setAuthToken();
  return (await axios.post(constant.menuEP, payload)) as any;
};

export const putMenu = async (payload: any, menu: string) => {
  await setAuthToken();
  return (await axios.put(constant.menuByIDEP(menu), payload)) as any;
};

export const postSecurityRole = async (payload: any) => {
  await setAuthToken();
  const { data }: AxiosResponse = await axios.post(
    constant.securityRoleEP,
    payload
  );
  return data;
};

export const putSecurityRole = async (payload: any, role: string) => {
  await setAuthToken();
  const { data }: AxiosResponse = await axios.put(
    constant.securityRoleByIDEP(role),
    payload
  );
  return data;
};

export const getRolePermissions = async (role: string) => {
  await setAuthToken();
  const { data }: AxiosResponse = await axios.get(
    constant.rolePermissions(role)
  );
  return data;
};

export const getRoleListFilter = async (filter?: string) => {
  await setAuthToken();
  const url = `${constant.roleListFilter}${
    filter ? `?apply_to=${filter}` : ''
  }`;
  const { data }: AxiosResponse = await axios.get(url);
  return data;
};

// Permission
export const getSecurityPermission = async (): Promise<any> => {
  await setAuthToken();
  const { data }: AxiosResponse = await axios.get(
    constant.securityPermissionEP
  );
  return data;
};

export const getPermissionById = async (permission: string) => {
  await setAuthToken();
  const { data } = await axios.get(
    constant.securityPermissionByIDEP(permission)
  );
  return data;
};

export const putPermission = async (
  permission: string,
  body: any
): Promise<any> => {
  const { data } = await axios.put(
    constant.securityPermissionByIDEP(permission),
    body
  );
  return data;
};

// Dealers
export const getDealers = async (filter: string = '', status: string = ''): Promise<any> => {
  await setAuthToken();
  const url = `${constant.dealerEP}?filter=${filter}&status=${status}`;
  const { data }: AxiosResponse = await axios.get(url);
  return data;
};

export const getDealersById = async (dealer: string): Promise<any> => {
  await setAuthToken();
  const { data }: AxiosResponse = await axios.get(
    constant.dealerByIdEP(dealer)
  );
  return data;
};

export const getDealersImg = async (dealer: string): Promise<any> => {
  await setAuthToken();
  const { data }: AxiosResponse = await axios.get(
    constant.dealerImageEP(dealer)
  );
  return data;
};

export const getOrganizationProfileDealer = async (
  dealer?: string
): Promise<any> => {
  await setAuthToken();
  const url = `${constant.organizationProfileDealerEP}${
    dealer ? '?dealer=' + dealer : ''
  }`;
  const { data }: AxiosResponse = await axios.get(url);
  return data;
};

export const getDealersNA = async (): Promise<any> => {
  await setAuthToken();
  const { data }: AxiosResponse = await axios.get(constant.dealerNAEP);
  return data;
};

export const getDealerUsers = async (
  dealer: string,
  filter?: string
): Promise<any> => {
  await setAuthToken();
  const url = `${constant.dealerUsersEP(dealer)}${
    filter ? `?filter=${filter}` : ''
  }`;
  const { data }: AxiosResponse = await axios.get(url);
  return data;
};

export const postDealer = async (body: any): Promise<any> => {
  await setAuthToken();
  const { data }: AxiosResponse = await axios.post(constant.dealerEP, body);
  return { data };
};

export const putDealerSetActive = async (
  dealer: string,
  activate: boolean
): Promise<any> => {
  await setAuthToken();
  const { data }: AxiosResponse = await axios.put(
    `${constant.dealerPutActiveEP(dealer)}?active=${activate}`
  );
  return { data };
};

export const putDealer = async (dealer: string, body: any): Promise<any> => {
  await setAuthToken();
  const { data }: AxiosResponse = await axios.put(
    constant.dealerByIdEP(dealer),
    body
  );
  return data;
};

export const putDealerInfo = async (
  body: any,
  dealer?: string
): Promise<any> => {
  await setAuthToken();
  const url = `${constant.organizationProfileDealerEP}${
    dealer ? '?dealer=' + dealer : ''
  }`;
  const { data }: AxiosResponse = await axios.put(url, body);
  return data;
};

export const putResendDealerInvitation = async (
  dealer: string
): Promise<any> => {
  await setAuthToken();
  const { data }: AxiosResponse = await axios.put(
    constant.dealerResendInvitationEP(dealer)
  );
  return data;
};

export const putDealerActivate = async (
  password: any,
  email: any,
  otp: any,
  dealer: any
): Promise<any> => {
  const body = {
    password: password,
    email: email,
    otp: otp,
    dealer: dealer
  };
  await setAuthToken();
  const { data }: AxiosResponse = await axios.put(
    constant.dealerActivateEP,
    body
  );
  return data;
};

export const deleteDealer = async (dealer: string): Promise<any> => {
  await setAuthToken();
  const { data }: AxiosResponse = await axios.delete(
    constant.dealerByIdEP(dealer)
  );
  return data;
};

export const putResendDealerUserInvitation = async (
  dealer_user: string
): Promise<any> => {
  await setAuthToken();
  const { data }: AxiosResponse = await axios.put(
    constant.dealerUserResendInvitationEP(dealer_user)
  );
  return data;
};

export const deleteDealerUser = async (dealer_user: string): Promise<any> => {
  await setAuthToken();
  const { data }: AxiosResponse = await axios.delete(
    constant.dealerUserDeleteEP(dealer_user)
  );
  return data;
};

export const postDealerUser = async (
  dealer: string,
  first_name: string,
  last_name: string,
  email: string,
  dealer_role: string
  // all_org: boolean,
  // organization: OrganizationInterface[] | null
): Promise<any> => {
  await setAuthToken();
  const body = {
    first_name: first_name,
    last_name: last_name,
    email: email,
    dealer_role: dealer_role
    // all_org: all_org,
    // organization: organization,
  };
  const { data }: AxiosResponse = await axios.post(
    constant.dealerUserPostEP(dealer),
    body
  );
  return data;
};

export const putDealerUser = async (
  dealer_user: string,
  first_name: string,
  last_name: string,
  email: string,
  dealer_role: string,
  status: boolean,
  dealer_u_allorg?: boolean
): Promise<any> => {
  await setAuthToken();
  const body = {
    first_name: first_name,
    last_name: last_name,
    email: email,
    dealer_role: dealer_role,
    status: status,
    all_org: dealer_u_allorg
  };
  const { data }: AxiosResponse = await axios.put(
    constant.dealerUserPutEP(dealer_user),
    body
  );
  return data;
};

export const getDealerUserDetails = async (
  dealer_user: string
): Promise<any> => {
  await setAuthToken();
  const { data }: AxiosResponse = await axios.get(
    constant.dealerUserDetailsEP(dealer_user)
  );
  return data;
};

export const postDealerUserAssignAccount = async (
  dealer_user: string,
  body: any
): Promise<any> => {
  await setAuthToken();
  const { data }: AxiosResponse = await axios.post(
    constant.dealerUserAssignAccountEP(dealer_user),
    body
  );
  return data;
};

export const deleteDealerUserAssignAccount = async (
  dealer_user: string,
  organization: string
): Promise<any> => {
  await setAuthToken();
  const { data }: AxiosResponse = await axios.delete(
    constant.dealerUserUnassignEP(dealer_user, organization)
  );
  return data;
};

export const putDealerUserEditSites = async (
  dealer_user: string,
  body: any
): Promise<any> => {
  await setAuthToken();
  const { data }: AxiosResponse = await axios.put(
    constant.dealerUserEditSiteEP(dealer_user),
    body
  );
  return data;
};

export const getDealerRolesList = async (dealer: string): Promise<any> => {
  await setAuthToken();
  const { data }: AxiosResponse = await axios.get(
    constant.dealerRolesListEP(dealer)
  );
  return data;
};

export const putDealerUserActive = async (
  dealer: string,
  dealer_user: string,
  active: boolean
): Promise<any> => {
  await setAuthToken();
  const { data }: AxiosResponse = await axios.put(
    `${constant.dealerUserPutActiveEP(dealer, dealer_user)}?active=${active}`
  );
  return { data };
};

export const getDealerAlarms = async (dealer: string): Promise<any> => {
  await setAuthToken();
  const { data } = await axios.get(constant.dealerAlarmsEP(dealer));
  return data;
};

export const getDealerUserOrganizations = async (dealer_user: string, filter: string = '') => {
  await setAuthToken();
  const { data } = await axios.get(`${constant.dealerUserOrganizationsEP(dealer_user)}?filter=${filter}`);
  return data;
};

// Countries
export const getCountries = async (): Promise<any> => {
  await setAuthToken();
  const { data }: AxiosResponse = await axios.get(constant.countriesEP);
  return data;
};

// Region
export const getRegion = async (): Promise<any> => {
  await setAuthToken();
  const { data }: AxiosResponse = await axios.get(constant.regionsEP);
  return data;
};

// Menu
export const getMenus = async (): Promise<any> => {
  try {
    await setAuthToken();
    const { data }: AxiosResponse = await axios.get(constant.menuEP);
    return data;
  } catch (error: any) {
    console.error('GET', constant.menuEP, error.response.data);
  }
};

// Organization
export const getOrganizations = async (
  status: 'all' | 'active' | 'inactive',
  filter?: string
): Promise<any> => {
  await setAuthToken();
  const url = `${constant.organizationEP}?filter=${filter}&status=${status}`;
  const { data }: AxiosResponse = await axios.get(url);
  return data;
};

export const getOrganizationDealer = async (
  dealer: string,
  status: 'all' | 'active' | 'inactive',
  filter?: string
): Promise<any> => {
  await setAuthToken();
  const url = `${constant.organizationByDealerEP(
    dealer
  )}?filter=${filter}&status=${status}`;
  const { data }: AxiosResponse = await axios.get(url);
  return data;
};

export const getOrganizationCounter = async (
  organization: string
): Promise<any> => {
  await setAuthToken();
  const { data }: AxiosResponse = await axios.get(
    constant.organizationCounterEP(organization)
  );
  return data;
};

export const getOrganizationDetail = async (
  organization: string
): Promise<any> => {
  await setAuthToken();
  const { data }: AxiosResponse = await axios.get(
    constant.organizationDetailEP(organization)
  );
  return data;
};

export const getOrginfoDealer = async (dealer?: string): Promise<any> => {
  await setAuthToken();
  const url = `${constant.orginfoDealerEP}${dealer ? '?dealer=' + dealer : ''}`;
  const { data }: AxiosResponse = await axios.get(url);
  return data;
};

export const getOrganizationController = async (
  organization: string,
  filter?: string
): Promise<any> => {
  await setAuthToken();
  const url = `${constant.organizationControllerEP(organization)}${
    filter ? `?filter=${filter}` : ''
  }`;
  const { data }: AxiosResponse = await axios.get(url);
  return data;
};

export const getOrganizationTransaction = async (
  organization: string,
  filter?: string
): Promise<any> => {
  await setAuthToken();
  const url = `${constant.organizationTransactionEP(organization)}${
    filter ? `?filter=${filter}` : ''
  }`;
  const { data }: AxiosResponse = await axios.get(url);
  return data;
};

export const getOrganizationUser = async (
  organization: string,
  filter?: string
): Promise<any> => {
  await setAuthToken();
  const url = `${constant.organizationUserEP(organization)}${
    filter ? `?filter=${filter}` : ''
  }`;
  const { data }: AxiosResponse = await axios.get(url);
  return data;
};

export const getOrganizationRole = async (
  organization: string
): Promise<any> => {
  await setAuthToken();
  const { data }: AxiosResponse = await axios.get(
    constant.organizationRoleEP(organization)
  );
  return data;
};

export const postOrganization = async (body: any): Promise<any> => {
  await setAuthToken();
  const { data }: AxiosResponse = await axios.post(
    constant.organizationEP,
    body
  );
  return data;
};

export const postOrganizationUser = async (
  organization: string,
  body: any
): Promise<any> => {
  await setAuthToken();
  const { data }: AxiosResponse = await axios.post(
    constant.organizationUserEP(organization),
    body
  );
  return data;
};

export const putOrganization = async (
  organization: string,
  body: any
): Promise<any> => {
  await setAuthToken();
  const { data }: AxiosResponse = await axios.put(
    constant.organizationUpdateEP(organization),
    body
  );
  return data;
};

export const putOrganizationUser = async (
  organization: string,
  user: string,
  body: any
): Promise<any> => {
  await setAuthToken();
  const { data }: AxiosResponse = await axios.put(
    constant.organizationUserPutEP(organization, user),
    body
  );
  return data;
};

export const putOrganizationSetActive = async (
  organization: string,
  activate: boolean
): Promise<any> => {
  await setAuthToken();
  const { data }: AxiosResponse = await axios.put(
    `${constant.organizationPutActiveEP(organization)}?active=${activate}`
  );
  return { data };
};

export const deleteOrganization = async (
  organization: string
): Promise<any> => {
  await setAuthToken();
  const { data }: AxiosResponse = await axios.delete(
    constant.organizationUpdateEP(organization)
  );
  return data;
};

export const deleteOrganizationUser = async (user: string) => {
  try {
    await setAuthToken();
    const response: AxiosResponse = await axios.delete(
      constant.securityUserParamEP(user)
    );
    return response;
  } catch (error: any) {
    console.error(
      'POST',
      constant.securityUserEP,
      error.response.data?.message || error.message
    );
    return error.response.data;
  }
};

export const deleteOrganizationUserFromOrg = async (
  organization_user: string
) => {
  try {
    await setAuthToken();
    const response: AxiosResponse = await axios.delete(
      constant.organizationDeleteUserEP(organization_user)
    );
    return response;
  } catch (error: any) {
    console.error(
      'DELETE',
      constant.organizationUserEP,
      error.response.data?.message || error.message
    );
    return error.response.data;
  }
};

export const getOrganizationSchedules = async (
  organization: string,
  filter: string,
  site?: string
): Promise<any> => {
  await setAuthToken();
  const url = `${constant.organizationSchedulesGetEP(
    organization
  )}?filter=${filter}${site ? `&site=${site}` : ''}`;
  const { data }: AxiosResponse = await axios.get(url);
  return data;
};

export const putResendOrgUserInvite = async (
  organization_user: string
): Promise<any> => {
  await setAuthToken();
  const { data }: AxiosResponse = await axios.put(
    constant.organizationUserResendInvitationEP(organization_user)
  );
  return data;
};

export const getSummaryOrganization = async (
  organization: string
): Promise<any> => {
  await setAuthToken();
  const { data }: AxiosResponse = await axios.get(
    constant.summaryOrganizationEP(organization)
  );
  return data;
};

export const getOrganizationKeypadCode = async (
  organization: string
): Promise<any> => {
  await setAuthToken();
  const { data }: AxiosResponse = await axios.get(
    constant.organizationKeypadCodeEP(organization)
  );
  return data;
};

export const putOrganizationKeypadCode = async (
  organization: string,
  body: any
): Promise<any> => {
  await setAuthToken();
  const { data }: AxiosResponse = await axios.put(
    constant.organizationKeypadCodeEP(organization),
    body
  );
  return data;
};

export const getOrganizationUserOrgCustomFields = async (
  organization: string
): Promise<any> => {
  await setAuthToken();
  const { data }: AxiosResponse = await axios.get(
    constant.organizationUserOrgCustomFieldsEP(organization)
  );
  return data;
};

export const postOrganizationUserOrgCustomFields = async (
  organization: string,
  body: any
): Promise<any> => {
  await setAuthToken();
  const { data }: AxiosResponse = await axios.post(
    constant.organizationUserOrgCustomFieldsEP(organization),
    body
  );
  return data;
}

export const getOrganizationAlarms = async (
  organization: string,
  site = ''
): Promise<any> => {
  await setAuthToken();
  const { data } = await axios.get(
    `${constant.organizationAlarmsEP(organization)}${
      site ? `?site=${site}` : ''
    }`
  );
  return data;
};

// Controller
export const getControllerDealer = async (
  dealer: string,
  filter?: string
): Promise<any> => {
  await setAuthToken();
  const url = `${constant.controllerByDealerEP(dealer)}${
    filter ? `?filter=${filter}` : ''
  }`;
  const { data }: AxiosResponse = await axios.get(url);
  return data;
};

export const postControllersPing = async (body: any): Promise<any> => {
  await setAuthToken();
  const { data } = await axios.post(constant.controllerPingEp, body);
  return data;
};
export const postControllersPingTimeout = async (body: any): Promise<any> => {
  await setAuthToken();
  const { data } = await axios.post(constant.controllerPingEp, body, {
    timeout: 15000
  });
  return data;
};

export const postControllersAssignSite = async (body: any): Promise<any> => {
  const { data } = await axios.post(constant.controllerAssignEp, body);
  return data;
};

export const getControllerRegisterInfo = async (
  controller: string
): Promise<any> => {
  const { data } = await axios.get(
    constant.controllerRegisterInfoEp(controller)
  );
  return data;
};

export const putControllerBasicData = async (
  controller: string,
  body: any
): Promise<any> => {
  const { data } = await axios.put(
    constant.controllerBasicInfo(controller),
    body
  );
  return data;
};

export const postControllerUsercards = async (
  organization: string,
  body: any[]
): Promise<any> => {
  await setAuthToken();
  const b = { usercards: body };
  const { data } = await axios.post(
    constant.controllerUsercardsEP(organization),
    b
  );
  return data;
};

export const getControllerDoors = async (controller: string): Promise<any> => {
  const { data } = await axios.get(constant.controllerDoors(controller));
  return data;
};

export const getController = async (controller: string): Promise<any> => {
  const { data } = await axios.get(constant.controllerDetails(controller));
  return data;
};

export const putDoorLabel = async (door: string, body: any): Promise<any> => {
  const { data } = await axios.put(constant.controllerDoorLabel(door), body);
  return data;
};

export const putDoorActive = async (
  door: string,
  active: boolean
): Promise<any> => {
  const { data } = await axios.put(constant.controllerDoorActive(door, active));
  return data;
};

export const putDoorOpen = async (
  door: string,
  open: boolean
): Promise<any> => {
  const { data } = await axios.put(constant.controllerDoorOpen(door, open));
  return data;
};

export const getVerificationCode = async (
  verificationUrl: string
): Promise<any> => {
  const { data } = await axios.get(verificationUrl);
  return data;
};

export const getControllerOrganization = async (
  organization: string,
  site?: string
): Promise<any> => {
  const url = `${constant.controllerByOrganizationListEP(organization)}?${
    site ? `&site=${site}` : ''
  }`;
  const { data } = await axios.get(url);
  return data;
};

export const getControllerOrganizationReader = async (
  organization: string,
  site?: string,
  filter: string = ''
): Promise<any> => {
  const url = `${constant.controllerByOrganizationReaderListEP(
    organization
  )}?serial_number=${filter}${site ? `&site=${site}` : ''}`;
  const { data } = await axios.get(url);
  return data;
};

export const postRemoveController = async (body: any): Promise<any> => {
  const { data } = await axios.post(constant.removeControllerEP, body);
  return data;
};

export const postReplaceController = async (body: any): Promise<any> => {
  const { data } = await axios.post(constant.controllerReplaceEP, body);
  return data;
};

export const postRebootController = async (
  controller: string
): Promise<any> => {
  const { data } = await axios.post(constant.controllerRebootEP(controller));
  return data;
};

export const getReplaceControllerStatus = async (
  organization: string,
  controller: string
): Promise<any> => {
  const { data } = await axios.get(
    constant.controllerReplaceStatusEP(organization, controller)
  );
  return data;
};

export const getAssignControllerStatus = async (
  organization: string,
  controller: string
): Promise<any> => {
  const { data } = await axios.get(
    constant.constrollerAssignStatusEP(organization, controller)
  );
  return data;
};

export const refreshController = async (controller: string): Promise<any> => {
  const { data } = await axios.put(constant.controllerRefreshEP(controller));
  return data;
};

export const startSyncController = async (controller: string): Promise<any> => {
  const { data } = await axios.put(constant.controllerSyncEntitiesStartEP(controller));
  return data;
};

/** Verify if the controller needs to be synced */
export const verifySyncController = async (
  controller: string
): Promise<{ need_sync: boolean; is_syncing: boolean }> => {
  const { data } = await axios.put(constant.verifySyncController(controller));
  return data;
};

export const verifyGlobalSyncController = async (
  controller: string
): Promise<any> => {
  const { data } = await axios.put(constant.controllerVerifySyncGlobalEP(controller));
  return data;
}

export const syncGlobalController = async (
  controller: string
): Promise<any> => {
  const { data } = await axios.put(constant.controllerSyncGlobalEP(controller));
  return data;
}

// Doors
export const getDoor = async (door: string): Promise<any> => {
  await setAuthToken();
  const { data } = await axios.get(constant.doorEP(door));
  return data;
};

export const getDoorEvents = async (
  door: string,
  queryString: string
): Promise<any> => {
  await setAuthToken();
  const { data } = await axios.get(constant.doorEventsEP(door, queryString));
  return data;
};

export const putDoor = async (door: string, body: any): Promise<any> => {
  await setAuthToken();
  const { data } = await axios.put(constant.doorEP(door), body);
  return data;
};

export const putDoorNote = async (door: string, body: any): Promise<any> => {
  await setAuthToken();
  const { data } = await axios.put(constant.doorNoteEP(door), body);
  return data;
};

export const putDoorActiveness = async (
  door: string,
  active: boolean
): Promise<any> => {
  await setAuthToken();
  const url = `${constant.doorSetActiveness(door, active)}`;
  const { data } = await axios.put(url);
  return data;
};

export const putDoorGrantAccess = async (
  door: string,
  action:
    | 'reset'
    | 'lock'
    | 'unlock'
    | 'grant-access'
    | 'grant-extend'
    | 'lockdown-grant-access'
): Promise<any> => {
  await setAuthToken();
  const { data } = await axios.put(constant.doorGrantAccessEP(door, action));
  return data;
};

export const putDoorEnable = async (
  door: string,
  enable: boolean
): Promise<any> => {
  await setAuthToken();
  const { data } = await axios.put(constant.doorEnableEP(door, enable));
  return data;
};

export const putRestoreAlarm = async (door: string): Promise<any> => {
  await setAuthToken();
  const { data } = await axios.put(constant.doorRestoreAlarmEP(door));
  return data;
};

export const getDoorOrtganization = async (
  organization: string,
  filter: string,
  site?: string,
  disabled?: boolean
): Promise<any> => {
  await setAuthToken();
  const url = `${constant.doorOrganizationEP(organization)}?filter=${filter}${
    site ? `&site=${site}` : ''
  }&disabled=${Boolean(disabled)}`;
  const { data } = await axios.get(url);
  return data;
};

// Events
export const getOrganizationEvents = async (
  organization: string,
  site?: string,
  controller?: string,
  type?:
    | 'Access'
    | 'Lockdown'
    | 'All'
    | 'Security'
    | 'Alarms'
    | 'Troubles'
    | 'Others'
    | 'Cloud',
  entity?:
    | 'All'
    | 'Organization'
    | 'Site'
    | 'Controller'
    | 'Door'
    | 'Holiday'
    | 'Schedule'
    | 'Accesslevel'
    | 'User_org'
    | 'Card'
    | 'Reader',
  description?: string,
  from?: string,
  to?: string
): Promise<any> => {
  await setAuthToken();
  let url = `${constant.getOrganizationEventsEP(organization)}?`;
  url += site ? `&site=${site}` : '';
  url += controller ? `&controller=${controller}` : '';
  url += type ? `&type=${type}` : '';
  url += entity ? `&entity=${entity}` : '';
  url += description ? `&description=${description}` : '';
  url += from ? `&from=${from}` : '';
  url += to ? `&to=${to}` : '';
  const { data } = await axios.get(url);
  return data;
};

export const getEventDetails = async (event: string): Promise<any> => {
  await setAuthToken();
  const { data } = await axios.get(constant.eventDetailsEP(event));
  return data.event;
}

// Abort Ping
export const abortPing = () => {
  abortController.abort();
};
// Testing Tool
export const postControllerPing = async (body: any): Promise<any> => {
  await setAuthToken();
  const { data } = await axios.post(constant.testingtoolPingEp, body, {
    signal: abortController.signal
  });
  return data;
};

export const postControllerPingTimeout = async (body: any): Promise<any> => {
  await setAuthToken();
  const { data } = await axios.post(constant.testingtoolPingEp, body, {
    timeout: 15000
  });
  return data;
};

export const postUnassignSite = async (body: any): Promise<any> => {
  await setAuthToken();
  const { data } = await axios.post(constant.testingtoolUnassignEp, body);
  return data;
};

export const postControllerAssignSite = async (body: any): Promise<any> => {
  await setAuthToken();
  const { data } = await axios.post(constant.testingtoolAssingEp, body);
  return data;
};

export const postControllerCmd = async (body: any): Promise<any> => {
  await setAuthToken();
  const { data } = await axios.post(constant.testingtoolCmdEp, body);
  return data;
};

export const getMqttMessage = async (): Promise<any> => {
  await setAuthToken();
  const { data } = await axios.get(constant.testingtoolMessageEp);
  return data;
};

export const postKeepChannel = async (channel: string): Promise<any> => {
  const body = { channel: channel };
  await setAuthToken();
  const { data } = await axios.post(constant.KeepChannelEP, body);
  return data;
};

// Payment
export const getPayment = async (dealer: string): Promise<any> => {
  const { data } = await axios.get(constant.getPaymentEP(dealer));
  return data;
};

export const postPayment = async (body: any): Promise<any> => {
  const { data } = await axios.post(constant.addPaymentEP, body);
  return data;
};

// Sites
export const getSitesByOrganization = async (
  organization: string,
  filter?: string
): Promise<any> => {
  await setAuthToken();
  const url = `${constant.siteByOrganizationEP(organization)}${
    filter ? `?filter=${filter}` : ''
  }`;
  const { data } = await axios.get(url);
  return data;
};

export const getSitesByOrganizationtoAssign = async (
  organization: string,
  user_org?: string
): Promise<any> => {
  await setAuthToken();
  const url = `${constant.siteByOrganizationtoAssignEP(organization)}${
    user_org ? `?filter=${user_org}` : ''
  }`;
  const { data } = await axios.get(url);
  return data;
};

export const postUserorgSiteEP = async (
  user_org: string,
  body: any
): Promise<any> => {
  await setAuthToken();
  const url = `${constant.userorgSiteEP(user_org)}`;
  const { data } = await axios.post(url, body);
  return data;
};

export const putUserorgSiteEP = async (
  user_org: string,
  site: string,
  body: any
): Promise<any> => {
  await setAuthToken();
  const url = `${constant.userorgPutSiteEP(user_org, site)}`;
  const { data } = await axios.put(url, body);
  return data;
};

export const deleteUserorgSiteEP = async (
  user_org: string,
  site: string
): Promise<any> => {
  await setAuthToken();
  const url = `${constant.userorgPutSiteEP(user_org, site)}`;
  const { data } = await axios.delete(url);
  return data;
};

export const getSitesDetails = async (organization: string): Promise<any> => {
  await setAuthToken();
  const url = `${constant.siteDetailsEP(organization)}`;
  const { data } = await axios.get(url);
  return data;
};

export const getSiteEvents = async (site: string): Promise<any> => {
  await setAuthToken();
  const { data } = await axios.get(constant.siteEventsEP(site));
  return data;
};

export const postSite = async (body: any): Promise<any> => {
  await setAuthToken();
  const { data } = await axios.post(constant.siteEP, body);
  return data;
};

export const putSite = async (site: any, body: any): Promise<any> => {
  await setAuthToken();
  const { data } = await axios.put(constant.siteActionEP(site), body);
  return data;
};

export const putSiteAssignReader = async (
  site: string,
  body: any
): Promise<any> => {
  await setAuthToken();
  const { data } = await axios.put(constant.siteAssignReaderEP(site), body);
  return data;
};

export const putSiteUnassignReader = async (
  site: string,
  body: any
): Promise<any> => {
  await setAuthToken();
  const { data } = await axios.put(constant.siteUnassignReaderEP(site), body);
  return data;
};

export const putSiteUpdateNotification = async (
  site: string,
  body: any
): Promise<any> => {
  await setAuthToken();
  const { data } = await axios.put(
    constant.siteUpdateNotificationEP(site),
    body
  );
  return data;
};

export const deleteSite = async (site: any): Promise<any> => {
  await setAuthToken();
  const { data } = await axios.delete(constant.siteActionEP(site));
  return data;
};

export const getSiteMasterController = async (site: string): Promise<any> => {
  await setAuthToken();
  const { data } = await axios.get(constant.siteHaveMasterControllerEP(site));
  return data;
};

export const postSiteFindController = async (site: string): Promise<any> => {
  await setAuthToken();
  const { data } = await axios.post(constant.siteFindControllerEP(site));
  return data;
};

export const getSiteKeypadCode = async (
  site: string
): Promise<any> => {
  await setAuthToken();
  const { data }: AxiosResponse = await axios.get(
    constant.siteKeypadCodeEP(site)
  );
  return data;
};

export const putSiteKeypadCode = async (
  site: string,
  body: any
): Promise<any> => {
  await setAuthToken();
  const { data }: AxiosResponse = await axios.put(
    constant.siteKeypadCodeEP(site),
    body
  );
  return data;
};

export const getSiteUserOrgCustomFields = async (
  site: string
): Promise<any> => {
  await setAuthToken();
  const { data }: AxiosResponse = await axios.get(
    constant.siteUserOrgCustomFieldsEP(site)
  );
  return data;
};

export const postSiteUserOrgCustomFields = async (
  site: string,
  body: any
): Promise<any> => {
  await setAuthToken();
  const { data }: AxiosResponse = await axios.post(
    constant.siteUserOrgCustomFieldsEP(site),
    body
  );
  return data;
}

export const getSiteTempKeypadCode = async (
  site: string
): Promise<any> => {
  await setAuthToken();
  const { data }: AxiosResponse = await axios.get(
    constant.siteTempKeypadCodeEP(site)
  );
  return data;
}

// shared
export const getTimezones = async (): Promise<any> => {
  await setAuthToken();
  const { data } = await axios.get(constant.timezonesEP);
  return data;
};

export const getSubscriptions = async (): Promise<any> => {
  await setAuthToken();
  const { data } = await axios.get(constant.subscriptionPlanEP);
  return data;
};

export const getStates = async (country: string): Promise<any> => {
  await setAuthToken();
  const { data } = await axios.get(`${constant.statesEP}?country=${country}`);
  return data;
};

export const getLanguages = async (): Promise<any> => {
  await setAuthToken();
  const { data } = await axios.get(constant.languagesEP);
  return data;
};

export const validateZipcode = async (
  zipcode: string,
  country: string,
  state_id: number
): Promise<any> => {
  await setAuthToken();
  const { data } = await axios.get(
    `${constant.zipcodeEP}?zipcode=${zipcode}&country=${country}&state=${state_id}`
  );
  return data;
};

export const discoveredOption = async (): Promise<any> => {
  await setAuthToken();
  const { data } = await axios.get(`${constant.discoveredOptionEP}`);
  return data;
};
// Schedules
export const getScheduleById = async (scheduleId: string): Promise<any> => {
  await setAuthToken();
  const { data } = await axios.get(constant.scheduleByIdEP(scheduleId));
  return data;
};
export const postSchedule = async (siteId: string, body: any): Promise<any> => {
  await setAuthToken();
  const { data } = await axios.post(constant.schedulePostEP(siteId), body);
  return data;
};
export const deleteSchedule = async (scheduleId: string): Promise<any> => {
  await setAuthToken();
  const { data } = await axios.delete(constant.scheduleDeleteEP(scheduleId));
  return data;
};
export const putSchedule = async (
  scheduleId: string,
  body: any
): Promise<any> => {
  await setAuthToken();
  const { data } = await axios.put(constant.schedulePutEP(scheduleId), body);
  return data;
};

export const putScheduleActive = async (
  scheduleId: string,
  active: boolean
): Promise<any> => {
  await setAuthToken();
  const { data } = await axios.put(
    `${constant.scheduleActivateEP(scheduleId)}?active=${active}`
  );
  return data;
};

// Holiday
export const getHolidayByOrganization = async (
  organization: string,
  filter: string,
  site?: string
): Promise<any> => {
  await setAuthToken();
  const url = `${constant.holidayOrganizationEPBySite(
    organization
  )}?filter=${filter}${site ? `&site=${site}` : ''}`;
  const { data } = await axios.get(url);
  return data;
};

export const postHoliday = async (id: string, body: any): Promise<any> => {
  await setAuthToken();
  const { data } = await axios.post(constant.holidayByIdEP(id), body);
  return data;
};

export const putHoliday = async (id: string, body: any): Promise<any> => {
  await setAuthToken();
  const { data } = await axios.put(constant.holidayByIdEP(id), body);
  return data;
};

export const deleteHoliday = async (id: string): Promise<any> => {
  await setAuthToken();
  const { data } = await axios.delete(constant.holidayByIdEP(id));
  return data;
};

export const getHoliday = async (id: string): Promise<any> => {
  await setAuthToken();
  const { data } = await axios.get(constant.holidayInfo(id));
  return data;
}

export const putHolidayActive = async (
  id: string,
  active: boolean
): Promise<any> => {
  await setAuthToken();
  const { data } = await axios.put(
    `${constant.holidayActivateEP(id)}?active=${active}`
  );
  return data;
};

// Access level
export const getAccesslevelByOrganization = async (
  organization: string,
  filter: string,
  site?: string
): Promise<any> => {
  await setAuthToken();
  const url = `${constant.accesslevelOrganizationEP(
    organization
  )}?filter=${filter}${site ? `&site=${site}` : ''}`;
  const { data } = await axios.get(url);
  return data;
};

export const getAccesslevelDoors = async (
  accesslevel: string
): Promise<any> => {
  await setAuthToken();
  const { data } = await axios.get(constant.accesslevelDoorEP(accesslevel));
  return data;
};

export const getAccesslevel = async (id: string): Promise<any> => {
  await setAuthToken();
  const { data } = await axios.get(constant.accesslevelByIdEP(id));
  return data;
}

export const postAccesslevel = async (id: string, body: any): Promise<any> => {
  await setAuthToken();
  const { data } = await axios.post(constant.accesslevelByIdEP(id), body);
  return data;
};

export const putAccesslevel = async (id: string, body: any): Promise<any> => {
  await setAuthToken();
  const { data } = await axios.put(constant.accesslevelByIdEP(id), body);
  return data;
};

export const deleteAccesslevel = async (id: string): Promise<any> => {
  await setAuthToken();
  const { data } = await axios.delete(constant.accesslevelByIdEP(id));
  return data;
};

export const putAccesslevelActive = async (
  id: string,
  active: boolean
): Promise<any> => {
  await setAuthToken();
  const { data } = await axios.put(
    `${constant.accesslevelActivateEP(id)}?active=${active}`
  );
  return data;
};

export const getUserorgOrganizationAccessLevel = async (
  organization: string,
  site?: string
): Promise<any> => {
  await setAuthToken();
  const { data } = await axios.get(
    `${constant.userorgOrganizationAccessLevel(organization)}${
      site ? `?site=${site}` : ''
    }`
  );
  return data;
};

// Access level manager
export const getAccessLevelManagerList = async (
  organization: string,
  site: string,
  filter: string = ''
): Promise<any> => {
  await setAuthToken();
  const { data } = await axios.get(constant.accessLevelManagerListEP(organization, site, filter));
  return data;
}

export const getUsersWithAccessLevel = async (
  organization: string,
  accesslevel: string
): Promise<any> => {
  await setAuthToken();
  const { data } = await axios.get(constant.accessLevelManagerUserorgEP(organization, accesslevel));
  return data;
}

export const getUsersWithoutAccessLevel = async (
  organization: string,
  accesslevel: string
): Promise<any> => {
  await setAuthToken();
  const { data } = await axios.get(constant.accessLevelManagerNoUserorgEP(organization, accesslevel));
  return data;
}

export const managerAddUsersToAccessLevel = async (
  organization: string,
  accesslevel: string,
  body: any
): Promise<any> => {
  await setAuthToken();
  const { data } = await axios.post(constant.accessLevelManagerUserorgEP(organization, accesslevel), body);
  return data;
}

export const managerRemoveUsersFromAccessLevel = async (
  organization: string,
  accesslevel: string,
  body: any
): Promise<any> => {
  await setAuthToken();
  const { data } = await axios.post(constant.accessLevelManagerNoUserorgEP(organization, accesslevel), body);
  return data;
}

export const managerGetUsersWithNoAccessLevel = async (
  organization: string,
  site: string = '',
  filter: string = ''
): Promise<any> => {
  await setAuthToken();
  const { data } = await axios.get(constant.accessLevelManagerNoAccessLevelEP(organization, site, filter));
  return data;
}

export const managerReplaceAccessLevel = async (
  organization: string,
  body: any
): Promise<any> => {
  await setAuthToken();
  const { data } = await axios.put(constant.accessLevelManagerReplaceEP(organization), body);
  return data;
}

export const getUserorgInfo = async (userorg: string): Promise<any> => {
  await setAuthToken();
  const { data } = await axios.get(constant.userorgInfoEP(userorg));
  return data;
};

export const getUserorgOrganization = async (
  organization: string,
  site?: string
): Promise<any> => {
  await setAuthToken();
  let url = `${constant.userorgOrganizationOrganization(organization)}?`;
  url += site ? `site=${site}` : '';
  const { data } = await axios.get(url);
  return data;
};

export const getUserorgFloorPlan = async (
  organization: string,
  site?: string
): Promise<any> => {
  await setAuthToken();
  let url = `${constant.userorgOrganizationOrganization(organization)}/users-floor-plan?`;
  url += site ? `site=${site}` : '';
  const { data } = await axios.get(url);
  return data;
};

export const postUserorgOrganization = async (
  organization: string,
  userorg: any
): Promise<any> => {
  await setAuthToken();
  const { data } = await axios.post(
    constant.userorgOrganization(organization),
    userorg
  );
  return data;
};

export const deleteUserorg = async (userorg: any): Promise<any> => {
  await setAuthToken();
  const { status } = await axios.delete(constant.userorgDelete(userorg));
  return status;
};

export const putUserorg = async (userorg: any): Promise<any> => {
  await setAuthToken();
  const { data } = await axios.put(
    constant.userorgUUID(userorg.user_org),
    userorg
  );
  return data;
};

export const putResendemail = async (userorg: string): Promise<any> => {
  await setAuthToken();
  const { data } = await axios.put(constant.userorgResendemail(userorg));
  return data;
};

export const deleteUserorgLogin = async (userorg: string): Promise<any> => {
  await setAuthToken();
  const { data } = await axios.delete(constant.userorgDeleteloginEP(userorg));
  return data;
};

export const putUserorgActive = async (
  userorg: string,
  active: boolean
): Promise<any> => {
  await setAuthToken();
  const { data } = await axios.put(
    `${constant.userorgActiveEP(userorg)}?active=${active}`
  );
  return data;
};

export const putUserorgMultisite = async (
  userorg: string,
  active: boolean
): Promise<any> => {
  await setAuthToken();
  const { data } = await axios.put(
    `${constant.userorgMultisiteEP(userorg)}?active=${active}`
  );
  return data;
};

export const getUserorgCustomFields = async (userorg: string): Promise<any> => {
  await setAuthToken();
  const { data } = await axios.get(constant.userorgCustomFieldsEP(userorg));
  return data;
}

export const putUserorgCustomFields = async (
  userorg: string,
  body: any
): Promise<any> => {
  await setAuthToken();
  const { data } = await axios.put(
    constant.userorgCustomFieldsEP(userorg),
    body
  );
  return data;
}

export const getUserorgKeypadCode = async ( userorg: string ): Promise<any> => {
  await setAuthToken();
  const { data }: AxiosResponse = await axios.get(constant.userorgKeypadCodeEP(userorg));
  return data;
};

export const postUserorgKeypadCode = async ( userorg: string, body: any ): Promise<any> => {
  await setAuthToken();
  const { data }: AxiosResponse = await axios.post(constant.userorgKeypadCodeEP(userorg), body);
  return data;
};

export const deleteUserorgKeypadCode = async ( userorg: string, body: any): Promise<any> => {
  await setAuthToken();
  const { data }: AxiosResponse = await axios.delete(constant.userorgKeypadCodeEP(userorg), { data: body });
  return data;
}

//

export const getDepartmentOrganization = async (
  organization: string
): Promise<any> => {
  await setAuthToken();
  const { data } = await axios.get(
    constant.departmentOrganizationOrganization(organization)
  );
  return data;
};

export const postDepartment = async (
  organization: string,
  name: string
): Promise<any> => {
  await setAuthToken();
  const body = {
    organization: organization,
    name: name
  };
  const { data } = await axios.post(constant.department, body);
  return data;
};

// Cards
export const getCardByOrganization = async (
  organization: string,
  filter: string,
  status: 'all' | 'active' | 'inactive',
  view: 'all' | 'assigned' | 'unassigned' | 'stolen' | 'lost',
  site?: string
): Promise<any> => {
  await setAuthToken();
  const url = `${constant.cardOrganizationEP(
    organization
  )}?filter=${filter}&status=${status}&view=${view}${
    site ? `&site=${site}` : ''
  }`;
  const { data } = await axios.get(url);
  return data;
};

export const getCardOrganizationDetails = async (organization: string, card: string): Promise<any> => {
  await setAuthToken();
  const { data } = await axios.get(constant.cardOrganizationDetailsEP(organization, card));
  return data;
}

export const postCard = async (
  organization: string,
  body: any
): Promise<any> => {
  await setAuthToken();
  const { data } = await axios.post(
    constant.cardOrganizationEP(organization),
    body
  );
  return data;
};

export const putAssignCard = async (card: string, body: any): Promise<any> => {
  await setAuthToken();
  const { data } = await axios.put(constant.cardAssignEP(card), body);
  return data;
};

export const getCardFormats = async (): Promise<any> => {
  await setAuthToken();
  const { data } = await axios.get(constant.cardFormatsEP);
  return data;
};

export const getCardGenerateCode = async (): Promise<any> => {
  await setAuthToken();
  const { data } = await axios.get(constant.cardGenerateCodeEP);
  return data;
};

export const getCard = async (id: string): Promise<any> => {
  await setAuthToken();
  const { data } = await axios.get(constant.cardDetails(id));
  return data;
}

export const putCard = async (id: string, body: any): Promise<any> => {
  await setAuthToken();
  const { data } = await axios.put(constant.cardById(id), body);
  return data;
};

export const deleteCard = async (id: string): Promise<any> => {
  await setAuthToken();
  const { data } = await axios.delete(constant.cardById(id));
  return data;
};
export const getUserorgOrganizationRoles = async (
  organization: string
): Promise<any> => {
  await setAuthToken();
  const { data } = await axios.get(
    constant.userorgOrganizationRoles(organization)
  );
  return data;
};

export const getUserorgOrganizationSites = async (
  organization: string
): Promise<any> => {
  await setAuthToken();
  const { data } = await axios.get(
    constant.userorgOrganizationSites(organization)
  );
  return data;
};

export const postUserorgOrganziationLogin = async (
  organization: string,
  body: any
): Promise<any> => {
  await setAuthToken();
  const { data } = await axios.post(
    constant.userorgOrganizationLogin(organization),
    body
  );
  return data;
};

export const putUserorgOrganziationLogin = async (
  organization: string,
  body: any
): Promise<any> => {
  await setAuthToken();
  const { data } = await axios.put(
    constant.userorgOrganizationLogin(organization),
    body
  );
  return data;
};

// user org images
export const getUserorgImage = async (userorg: string, small?: boolean): Promise<any> => {
  await setAuthToken();
  const { data } = await axios.get(`${constant.userorgImageEP(userorg)}?small=${small}`);
  return data;
};

export const getUserorgImageURL = async (url: string): Promise<any> => {
  await setAuthToken();
  const { data } = await axios.get(url);
  return data;
};

export const deleteUserorgCode = async (userorg: string): Promise<any> => {
  await setAuthToken();
  const { data } = await axios.delete(constant.userorgRemovecodeEP(userorg));
  return data;
};

export const putUserorgImage = async (
  userorg: string,
  photo: string
): Promise<any> => {
  await setAuthToken();
  const { data } = await axios.put(constant.userorgImageEP(userorg), {
    photo: photo
  });
  return data;
};

export const deleteUserorgImage = async (userorg: string): Promise<any> => {
  await setAuthToken();
  const { data } = await axios.delete(constant.userorgImageEP(userorg));
  return data;
};

export const getUserorgCards = async (userorg: string): Promise<any> => {
  await setAuthToken();
  const { data } = await axios.get(constant.userorgCards(userorg));
  return data;
};

export const getCardOrganizationActiveUnassigned = async (
  organization: string
): Promise<any> => {
  await setAuthToken();
  const { data } = await axios.get(
    constant.cardOrganizationEP(organization) + '?status=active&view=unassigned'
  );
  return data;
};

export const putCardAssign = async (
  card: any,
  userorg: string
): Promise<any> => {
  await setAuthToken();
  const body = {
    user_org: userorg,
    activation_date: card.activation_date?.split('T')[0],
    due_date: card.due_date?.split('T')[0]
  };
  const { data } = await axios.put(constant.cardAssignEP(card.card), body);
  return data;
};

export const putCardUnassign = async (card: string): Promise<any> => {
  await setAuthToken();
  const { data } = await axios.put(constant.cardUnassignEP(card));
  return data;
};

export const putCardActive = async (
  card: string,
  active: boolean
): Promise<any> => {
  await setAuthToken();
  const { data } = await axios.put(
    `${constant.cardActiveEP(card)}?active=${active}`
  );
  return data;
};

export const getCardListByUser = async (
  organization: string = '',
  filter: string = '',
  user_org: string = ''
): Promise<any> => {
  await setAuthToken();
  let url = `${constant.cardListByUserEP()}?`;
  // url += filter ? `filter=${filter}&` : "";
  url += organization ? `organization=${organization}&` : '';
  url += filter ? `filter=${filter}&` : '';
  url += user_org ? `user_org=${user_org}` : '';
  const { data } = await axios.get(url);
  return data;
};

export const getCardInfoByNumber = async (
  card_number: string,
  organization?: string
): Promise<any> => {
  await setAuthToken();
  let url = `${constant.cardInfoByNumberEP(card_number)}`;
  url += organization ? `?organization=${organization}` : '';
  const { data } = await axios.get(url);
  return data;
};

// Tasks
export const getTasks = async (
  organization?: string,
  site?: string,
  serial?: string,
  status?: 'N' | 'P' | 'X' | 'F',
  from?: string,
  to?: string,
  search?: string
): Promise<any> => {
  await setAuthToken();
  let url = `${constant.tasksGetEP}?`;
  url += organization ? `organization=${organization}&` : '';
  url += site ? `site=${site}&` : '';
  url += serial ? `serial=${serial}&` : '';
  url += status ? `status=${status}&` : '';
  url += from ? `from=${from}&` : '';
  url += to ? `to=${to}&` : '';
  url += search ? `search=${search}&` : '';
  const { data } = await axios.get(url);
  return data;
};

// Table
export const getTableOrganization = async (): Promise<any> => {
  await setAuthToken();
  const { data } = await axios.get(constant.tableOrganizationEP);
  return data;
};

export const getTableSite = async (organization?: string): Promise<any> => {
  await setAuthToken();
  let url = `${constant.tableSiteByOrganizationEP}?organization=${organization}`;
  const { data } = await axios.get(url);
  return data;
};

// Readers
export const getReaderOrganization = async (
  organization: string,
  filter?: string
): Promise<any> => {
  await setAuthToken();
  const url = `${constant.readerOrganizationEP(organization)}${
    filter ? `?filter=${filter}` : ''
  }`;
  const { data } = await axios.get(url);
  return data;
};

export const getReaderAlive = async (reader: string): Promise<any> => {
  await setAuthToken();
  const { data } = await axios.get(constant.readerAliveEP(reader));
  return data;
};

export const putReaderStart = async (body: any): Promise<any> => {
  await setAuthToken();
  const { data } = await axios.put(constant.readerStartScanEP, body);
  return data;
};

export const putReaderCancel = async (body: any): Promise<any> => {
  await setAuthToken();
  const { data } = await axios.put(constant.readerCancelScanEP, body);
  return data;
};

// Mobile pass
export const getMobilepassGenerateNumber = async (): Promise<any> => {
  await setAuthToken();
  const { data } = await axios.get(constant.mobilepassGenereteCardNumberEP);
  return data;
};

export const getMobilepassGenerateCodesite = async (
  site: string
): Promise<any> => {
  await setAuthToken();
  const { data } = await axios.get(constant.mobilepassGenereteCodeSite(site));
  return data;
};

export const putMobilepassAlive = async (
  card: string,
  body: any
): Promise<any> => {
  await setAuthToken();
  const { data } = await axios.put(constant.mobilepassAliveEP(card), body);
  return data;
};

export const postMobilepassOrganization = async (
  organization: string,
  body: any
): Promise<any> => {
  await setAuthToken();
  const { data } = await axios.post(
    constant.mobilepassOrganizationEP(organization),
    body
  );
  return data;
};

export const putMobilepassEditOrganization = async (
  mobilepass: string,
  body: any
): Promise<any> => {
  await setAuthToken();
  const { data } = await axios.put(
    constant.mobilepassEditOrganizationEP(mobilepass),
    body
  );
  return data;
};

export const putMobilepassActived = async (
  organization: string,
  body: any
): Promise<any> => {
  await setAuthToken();
  const { data } = await axios.put(
    constant.mobilepassActivedEP(organization),
    body
  );
  return data;
};

export const postMobileSendmail = async (body: any) => {
  await setAuthToken();
  const { data } = await axios.post(constant.mobilepassSendEmailEP, body);
  return data;
};

export const putMobileReenroll = async (card: string, body: any) => {
  await setAuthToken();
  const { data } = await axios.put(constant.mobilepassReenrollEP(card), body);
  return data;
};

export const postDealerRequest = async (body: any) => {
  const { data } = await axios.post(constant.dealerRequestApplyEP, body);
  return data;
};

export const getDealerRequests = async (filter?: string): Promise<any> => {
  await setAuthToken();
  const url = `${constant.dealerRequestEP}${filter ? `?filter=${filter}` : ''}`;
  const { data }: AxiosResponse = await axios.get(url);
  return data;
};

export const getDealerRequestByID = async (id: string): Promise<any> => {
  await setAuthToken();
  const { data } = await axios.get(constant.dealerRequestByIdEP(id));
  return data;
};

export const approveDealerRequest = async (id: string): Promise<any> => {
  await setAuthToken();
  const { data } = await axios.put(constant.dealerRequestApproveEP(id));
  return data;
};

export const denyDealerRequest = async (
  id: string,
  body: any
): Promise<any> => {
  await setAuthToken();
  const { data } = await axios.put(constant.dealerRequestDenyEP(id), body);
  return data;
};

export const reviewDealerRequest = async (id: string): Promise<any> => {
  await setAuthToken();
  const { data } = await axios.put(constant.dealerRequestReviewEP(id));
  return data;
};

export const putDealerRequestByID = async (
  id: string,
  body: any
): Promise<any> => {
  await setAuthToken();
  const { data } = await axios.put(constant.dealerRequestByIdEP(id), body);
  return data;
};

export const postDealerRequestEmailList = async (body: any) => {
  const data = await axios.post(constant.dealerRequestEmailListEP, body);
  return data;
};

export const getDealerRequestEmailList = async (): Promise<any> => {
  await setAuthToken();
  const { data } = await axios.get(constant.dealerRequestEmailListEP);
  return data;
};

export const getControllerSyncStatus = async (
  controller: string
): Promise<any> => {
  await setAuthToken();
  const { data } = await axios.get(
    constant.getControllerSync(controller)
  );
  return data;
};

export const getControllerTamperOptions = async (
  controller: string
): Promise<any> => {
  await setAuthToken();
  const { data } = await axios.get(
    constant.getConfigTamperController(controller)
  );
  return data;
};

export const putControllerTamperOptions = async (
  controller: string,
  body: any
): Promise<any> => {
  await setAuthToken();
  const data = await axios.put(
    constant.getConfigTamperController(controller),
    body
  );
  return data;
};

export const postRestoreControllerTamperedAlarm = async (
  controller: string
) => {
  await setAuthToken();
  const { data } = await axios.post(
    constant.postRestorerControllerTamperAlarm(controller)
  );

  return data;
};

export const getReadersByControllerrUUID = async (controller: string) => {
  await setAuthToken();

  const { data } = await axios.get(
    constant.getReadersByControllerUUID(controller)
  );

  return data;
};
export const postResource = async (body: any) => {
  const { data } = await axios.post(constant.resourcesEP, body);
  return data;
};

export const getResources = async (filter?: string): Promise<any> => {
  await setAuthToken();
  const url = `${constant.resourcesEP}${filter ? `?filter=${filter}` : ''}`;
  const { data }: AxiosResponse = await axios.get(url);
  return data;
};

export const getResourceByID = async (id: string): Promise<any> => {
  await setAuthToken();
  const { data } = await axios.get(constant.resourcesByIdEP(id));
  return data;
};

export const putResourceByID = async (id: string, body: any): Promise<any> => {
  await setAuthToken();
  const { data } = await axios.put(constant.resourcesByIdEP(id), body);
  return data;
};

export const deleteResourceByID = async (id: string): Promise<any> => {
  await setAuthToken();
  const { data }: AxiosResponse = await axios.delete(
    constant.resourcesByIdEP(id)
  );
  return data;
};

export const getInterfacersBycontroller = async (controller: string) => {
  await setAuthToken();

  const { data } = await axios.get(
    constant.getInterfacersBycontroller(controller)
  );

  return data;
};

export const putInterfacersModeByController = async (
  controller: string,
  body: any
) => {
  await setAuthToken();
  const { data } = await axios.put(
    constant.getInterfacersBycontroller(controller),
    body
  );
  return data;
};

export const putReaderTamperOptions = async (reader: string, body: any) => {
  await setAuthToken();
  const data = await axios.put(
    constant.readerUpdateTamperOptions(reader),
    body
  );
  return data;
};

export const putChangeReaderDisplayName = async (reader: string, body: any) => {
  await setAuthToken();
  const { data } = await axios.put(constant.readerUpdateData(reader), body);
  return data;
};

export const postRestoreReaderAlarm = async (reader: string) => {
  await setAuthToken();
  const data = await axios.post(constant.readerRestore(reader));
  return data;
};

// Lockdown
export const getLockdownBySite = async (site: string) => {
  await setAuthToken();
  const { data } = await axios.get(constant.lockdownParamEP(site));
  return data;
};

export const putLockdownBySite = async (lockdown: string, body: any) => {
  await setAuthToken();
  const { data } = await axios.put(constant.lockdownParamEP(lockdown), body);
  return data;
};

export const putLockdownStart = async (
  lockdown: string,
  body: any,
  active: boolean
) => {
  await setAuthToken();
  const { data } = await axios.put(
    `${constant.lockdownStartEP(lockdown)}?active=${active}`,
    body
  );
  return data;
};

//Subscription
export const getDealerSubscription = async (dealer: string) => {
  await setAuthToken();
  const { data } = await axios.get(constant.getDealerSubscription(dealer));
  return data;
};

export const getOrganizationSubscription = async (organization: string) => {
  await setAuthToken();
  const { data } = await axios.get(
    constant.getOrganizationSubscription(organization)
  );
  return data;
};

export const getOrganizationVerifyName = async (name: string) => {
  await setAuthToken();
  const { data } = await axios.get(constant.organizationVerifyNameEP(name));
  return data;
};

export const organizationChangeMultisite = async (
  organization: string,
  active: boolean
) => {
  await setAuthToken();
  const { data } = await axios.put(
    `${constant.organizationChangeMultisite(organization)}?active=${active}`
  );
  return data;
};

export const getLockdownByOrganization = async (organization: string) => {
  await setAuthToken();
  const { data } = await axios.get(constant.lockdownStartedEP(organization));
  return data;
};

export const getNewSubscriptionValues = async (
  organization: string,
  doors: number,
  mobilepass: number,
  remove: boolean
) => {
  await setAuthToken();
  const { data } = await axios.get(
    `${constant.getNewSubscriptionValues(
      organization,
      doors,
      mobilepass
    )}&remove=${remove}`
  );
  return data;
};

export const updateSubscription = async (
  organization: string,
  remove: boolean,
  body: any
) => {
  await setAuthToken();
  const { data } = await axios.put(
    `${constant.updateSubscription(organization)}?remove=${remove}`,
    body
  );
  return data;
};

export const getInvoices = async (organization: string) => {
  await setAuthToken();
  const { data } = await axios.get(constant.getInvoices(organization));
  return data;
};

export const getInvoicePdf = async (organization: string, payment: string) => {
  await setAuthToken();
  const { data } = await axios.get(
    `${constant.getInvoices(organization)}/${payment}`,
    { responseType: 'blob' }
  );
  return data;
};

export const deletePayment = async (id: string) => {
  await setAuthToken();
  const { data } = await axios.delete(constant.deletePaymentEP(id));
  return data;
};

export const getPricing = async (plan: string) => {
  await setAuthToken();
  const { data } = await axios.get(constant.getPricing);
  return data;
};

export const postPricing = async (body: any) => {
  await setAuthToken();
  const { data } = await axios.post(`${constant.getPricing}`, body);
  return data;
};
export const getEventsByEntity = async (
  organization: string,
  entity:
    | 'User_org'
    | 'Holiday'
    | 'Card'
    | 'Schedule'
    | 'Accesslevel'
    | 'Input'
    | 'Output'
    | 'Site'
    | 'Floor_plan',
  entityUuid: string,
  description: string,
  site: string,
  from: string,
  to: string
): Promise<any> => {
  await setAuthToken();
  const { data } = await axios.get(
    constant.eventsByEntity(
      organization,
      entity,
      entityUuid,
      description,
      site,
      from,
      to
    )
  );
  return data;
};

export const defaultPaymentEP = async (id: string) => {
  await setAuthToken();
  const { data } = await axios.put(`${constant.defaultPaymentEP(id)}`);
  return data;
};

export const getOrganizationInputs = async (
  organization: string,
  site: string = '',
  filter: string = ''
) => {
  await setAuthToken();
  const { data } = await axios.get(
    constant.getInputsByOrganization(organization, site, filter)
  );
  return data;
};

export const getConnectionMethods = async () => {
  await setAuthToken();
  const { data } = await axios.get(constant.geConnectionMethods);
  return data;
};

export const putInputData = async (input: string, body: any) => {
  await setAuthToken();
  const { data } = await axios.put(constant.updateInputData(input), body);
  return data;
};

export const getInput = async (input: string) => {
  await setAuthToken();
  const { data } = await axios.get(constant.inputDetails(input));
  return data;
}

export const getOutputListByOrganization = async (
  organization: string,
  site: string,
  filter: string
) => {
  await setAuthToken();
  const { data } = await axios.get(
    constant.getOutputsByOrganization(organization, site, filter)
  );
  return data;
};

export const putOutputData = async (output: string, body: any) => {
  await setAuthToken();
  const { data } = await axios.put(constant.updateOutputData(output), body);
  return data;
};

export const getOutput = async (output: string) => {
  await setAuthToken();
  const { data } = await axios.get(constant.outputDetails(output));
  return data;
}

export const getUsersReportList = async (
  organization: string,
  filter: string,
  site: string,
  accesslevel: string,
  department: string,
  active: boolean | string,
  from: string,
  to: string,
  dateFilter: string
) => {
  await setAuthToken();
  const { data } = await axios.get(
    constant.reportUsers(
      organization,
      filter,
      site,
      accesslevel,
      department,
      active,
      from,
      to,
      dateFilter
    )
  );
  return data;
};

export const getFileUsersReportList = async (
  organization: string,
  filter: string,
  site: string,
  accesslevel: string,
  department: string,
  active: boolean | string,
  from: string,
  to: string,
  dateFilter: string,
  excel: boolean,
  option_date: string
) => {
  await setAuthToken();
  const { data } = await axios.get(
    constant.reportUsersFile(
      organization,
      filter,
      site,
      accesslevel,
      department,
      active,
      from,
      to,
      dateFilter,
      excel,
      option_date
    ),
    { responseType: 'blob' }
  );
  return data;
};

export const getCardsReportList = async (
  organization: string,
  queryString: string
) => {
  await setAuthToken();
  const { data } = await axios.get(
    constant.reportCards(organization, queryString)
  );
  return data;
};

export const getFileCardsReportList = async (
  organization: string,
  queryString: string
) => {
  await setAuthToken();
  const { data } = await axios.get(
    constant.reportCardsFile(organization, queryString),
    { responseType: 'blob' }
  );
  return data;
};

// Firmware
/**
 *
 * @param controller uuid of controller
 * @param body {controller: string, reader: string, firmware: string}
 * @returns
 */
export const postFirmwareControllerInstall = async (
  controller: string,
  body: any
) => {
  await setAuthToken();
  const { data } = await axios.post(
    constant.controllerFirmwareInstallEP(controller),
    body
  );
  return data;
};

/**
 *
 * @param reader uuid of reader
 * @param body {controller: string, reader: string, firmware: string}
 * @returns
 */
export const postFirmwareReaderInstall = async (reader: string, body: any) => {
  await setAuthToken();
  const { data } = await axios.post(
    constant.readerFirmwareInstallEP(reader),
    body
  );
  return data;
};

/**
 *
 * @param body {controller: string, reader: string, firmware: string}
 * @returns
 */
export const postFirmwareInstall = async (body: any) => {
  await setAuthToken();
  const { data } = await axios.post(constant.firmwareInstallEP, body);
  return data;
};

/**
 *
 * @param reader uuid of reader
 * @param controller uuid of controller
 * @returns
 */
export const deleteFirmwareInstall = async (controller:string= '', reader:string = '') => {
  await setAuthToken();
  const { data } = await axios.delete(constant.firmwareAbortEP(controller, reader));
  return data;
};

/**
 *
 * @param reader uuid of reader
 * @param controller uuid of controller
 * @returns
 */
export const removeFirmwareInstallFile = async (controller:string= '', reader:string = '') => {
  await setAuthToken();
  const { data } = await axios.delete(constant.firmwareRemoveEP(controller, reader));
  return data;
};

/**
 *
 * @param body {controller: string, reader: string}
 * @returns
 */
export const putFirmwareError = async (body: any) => {
  await setAuthToken();
  const { data } = await axios.put(constant.firmwareMarkErrorEP, body);
  return data;
};

/**
 *
 * @param body {fwfile: .ccp extension file}
 * @returns
 */
export const postFirmwareUpload = async (body: FormData) => {
  await setAuthToken();
  const { data } = await axios.post(constant.firmwareUploadEP, body, {
    headers: { 'Content-Type': 'multipart/form-data' }
  });
  return data;
};

/**
 *
 * @param body {"firmware_type": "C",
                "firmware_id": "3fa85f64-5717-4562-b3fc-2c963f66afa6",
                "mandatory": true,
                "launch": true,
                "product_code": "string",
                "product_family": 999999,
                "product_id": 999999,
                "firmware_version": 999999,
                "firmware_revision": 999999,
                "firmware_build": 999999,
                "firmware_build_day": 31,
                "firmware_build_month": 12,
                "firmware_build_year": 3000,
                "release_notes": "string"}
 * @returns
 */
export const postFirmware = async (body: any) => {
  await setAuthToken();
  const { data } = await axios.post(constant.firmwareEP, body);
  return data;
};

/**
 *
 * @returns
 */
export const getFirmwareList = async () => {
  await setAuthToken();
  const { data } = await axios.get(constant.firmwareEP);
  return data;
};

/**
 *
 * @param body {"firmware_type": "C",
                "firmware_id": "3fa85f64-5717-4562-b3fc-2c963f66afa6",
                "mandatory": true,
                "launch": true,
                "product_code": "string",
                "product_family": 999999,
                "product_id": 999999,
                "firmware_version": 999999,
                "firmware_revision": 999999,
                "firmware_build": 999999,
                "firmware_build_day": 31,
                "firmware_build_month": 12,
                "firmware_build_year": 3000,
                "release_notes": "string"}
 * @param firmware UUID of firmware
 * @returns
 */
export const putFirmware = async (firmware: string, body: any) => {
  await setAuthToken();
  const { data } = await axios.put(constant.firmwareFirmwareEP(firmware), body);
  return data;
};

/**
 *
 * @param firmware UUID of firmware
 * @returns
 */
export const deleteFirmware = async (firmware: string) => {
  await setAuthToken();
  const { data } = await axios.delete(constant.firmwareFirmwareEP(firmware));
  return data;
};

/**
 *
 * @param firmware UUID of firmware
 * @returns
 */
export const getFirmware = async (firmware: string) => {
  await setAuthToken();
  const { data } = await axios.get(constant.firmwareFirmwareEP(firmware));
  return data;
};

/**
 *
 * @param firmware UUID of firmware
 * @returns
 */
export const postFirmwareLaunch = async (firmware: string) => {
  await setAuthToken();
  const { data } = await axios.post(constant.firmwareLaunchEP(firmware));
  return data;
};

/**
 *
 * @param firmware UUID of firmware
 * @returns
 */
export const deleteFirmwareLaunch = async (firmware: string) => {
  await setAuthToken();
  const { data } = await axios.delete(constant.firmwareLaunchEP(firmware));
  return data;
};

/**
 *
 * @param firmware UUID of firmware
 * @returns
 */
export const postFirmwareDiscontinue = async (firmware: string) => {
  await setAuthToken();
  const { data } = await axios.post(constant.firmwareDiscontinueEP(firmware));
  return data;
};

/**
 *
 * @param firmware UUID of firmware
 * @param type string
 * @returns
 */
export const getFirmwaresByControllerOrReader = async (firmware: string, type: string) => {
  await setAuthToken();
  const { data } = await axios.get(constant.firmwareControllerOrReader(firmware, type));
  return data;
};

/**
 *
 * @param body {controller: string, reader: string, firmware: string}
 * @returns
 */
export const postFirmwareTransfer = async (body: any) => {
  await setAuthToken();
  const { data } = await axios.post(constant.firmwareTransferEP, body);
  return data;
};

/**
 *
 * @param body {controller: string, reader: string}
 * @returns
 */
export const postFirmwareUpdate = async (body: any) => {
  await setAuthToken();
  const { data } = await axios.post(constant.firmwareUpdateEP, body);
  return data;
};

// REPORTS
export const getEventsReportList = async (
  organization: string,
  filter: string,
  site: string,
  event_class: string,
  object_type: string,
  object_event: string,
  event_type: string,
  object: string,
  from: string,
  to: string
) => {
  await setAuthToken();
  const { data } = await axios.get(
    constant.reportEvents(
      organization,
      filter,
      site,
      event_class,
      object_type,
      object_event,
      event_type,
      object,
      from,
      to
    )
  );
  return data;
};

export const getEventsReportExport = async (
  organization: string,
  filter: string,
  site: string,
  event_class: string,
  object_type: string,
  object_event: string,
  event_type: string,
  from: string,
  to: string,
  option_date: string,
  excel: boolean,
  print_all: boolean
) => {
  await setAuthToken();
  const { data } = await axios.get(
    constant.reportEventsExport(
      organization,
      filter,
      site,
      event_class,
      object_type,
      object_event,
      event_type,
      from,
      to,
      option_date,
      excel,
      print_all
    ),
    { responseType: 'blob' }
  );
  return data;
};

export const getEventsType = async (entity: string) => {
  await setAuthToken();
  const { data } = await axios.get(
    `${constant.eventsTypes()}?entity=${entity}`
  );
  return data;
};

export const getReportDefinitions = async (
  organization: string,
  report_type: 'Events' | 'Cards' | 'User'
) => {
  await setAuthToken();
  const { data } = await axios.get(
    constant.reportDefinitionEP(organization, report_type)
  );
  return data;
};

export const postReportOrganization = async (
  organization: string,
  body: any
) => {
  await setAuthToken();
  const { data } = await axios.post(
    constant.reportOrgnizationEP(organization),
    body
  );
  return data;
};

export const putReportOrganization = async (
  organization: string,
  report: string,
  body: any
) => {
  await setAuthToken();
  const { data } = await axios.put(
    constant.reportOrgnizationReportEP(organization, report),
    body
  );
  return data;
};

export const deleteReportOrganization = async (
  organization: string,
  report: string
) => {
  await setAuthToken();
  const { data } = await axios.delete(
    constant.reportOrgnizationReportEP(organization, report)
  );
  return data;
};

export const getReportObjects = async (
  organization: string,
  site: string,
  object_type: string
) => {
  await setAuthToken();
  const { data } = await axios.get(
    constant.reportObjectsEP(organization, site, object_type)
  );
  return data;
};

export const getUsersByRoleUUID = async (role: string, filter: string) => {
  await setAuthToken();
  const { data } = await axios.get(constant.getUsersByRole(role, filter));
  return data;
};

//KPROG

export const getKprog = async (organization: string, serial: any) => {
  await setAuthToken();
  const { data } = await axios.get(constant.getKprog(organization, serial));
  return data;
};

export const assignKprog = async (site: any) => {
  await setAuthToken();
  const { data } = await axios.put(constant.assignKprog(site));
  return data;
};

export const getDealerSubValues = async (
  dealer: string,
  doors: number,
  mobile: number,
  remove: boolean = false
) => {
  await setAuthToken();
  const { data } = await axios.get(
    `${constant.getNewSubscriptionDealerValues(
      dealer,
      doors,
      mobile
    )}&remove=${remove}`
  );
  return data;
};

export const getDealerInvoices = async (dealer: string) => {
  await setAuthToken();
  const { data } = await axios.get(constant.getDealerInvoices(dealer));
  return data;
};

export const getDealerInvoicesPayment = async (
  dealer: string,
  payment: string
) => {
  await setAuthToken();
  const { data } = await axios.get(
    constant.getDealerPaymentInvoices(dealer, payment)
  );
  return data;
};

export const getDealerInvoicesPaymentPDF = async (
  dealer: string,
  payment: string
) => {
  await setAuthToken();
  const { data } = await axios.get(
    `${constant.getDealerPaymentInvoices(dealer, payment)}`,
    { responseType: 'blob' }
  );
  return data;
};

export const postDealerSubscription = async (dealer: string, body: any) => {
  await setAuthToken();
  const { data } = await axios.post(
    constant.postDealerSUbscription(dealer),
    body
  );
  return data;
};

export const updateDealerSubscription = async (
  dealer: string,
  remove: boolean,
  body: any
) => {
  await setAuthToken();
  const { data } = await axios.put(
    `${constant.updateDealerSubscription(dealer)}?remove=${remove}`,
    body
  );
  return data;
};

export const postDealerPreRegister = async (body: { email: string }) => {
  const { data } = await axios.post(constant.dealerRequestApplyEmailCode, body);
  return data;
};

export const getEmailByCode = async (code: string) => {
  await setAuthToken();
  const { data } = await axios.get(constant.dealerRequestGetEmailByCode(code));
  return data;
};

export const getStripeConfig = async () => {
  await setAuthToken();
  const { data } = await axios.get(`${constant.getStripeConfigEP}`);
  return data;
};

export const deleteReader = async (readerUUid: string) => {
  await setAuthToken();

  const { data } = await axios.delete(constant.readerDelete(readerUUid));

  return data;
};

export const postEmailSupport = async (body: any) => {
  await setAuthToken();
  const { data } = await axios.post(
    constant.emailSupportEP,
    body
  );
  return data;
};

export const putPreferences = async (body: any, user: string) => {
  await setAuthToken();
  const { data } = await axios.put(
    constant.userPreferencesEP(user),
    body
  );
  return data;
};

// Floor plan
export const getFloorplans = async (site: string, filter?: string) => {
  await setAuthToken();
  const { data } = await axios.get(`${constant.floorplanSiteEP(site)}?filter=${filter}`);
  return data;
};

export const getFloorplanDetails = async (floor_plan: string) => {
  await setAuthToken();
  const { data } = await axios.get(
    `${constant.floorplanDetailsEP(floor_plan)}`
  );
  return data;
};

export const getFloorplansImg = async (floor_plan_id: string) => {
  await setAuthToken();
  const { data } = await axios.get(`${constant.floorplanImageEP(floor_plan_id)}`);
  return data;
};

export const postFloorplan = async (site: string, body: any) => {
  await setAuthToken();
  const { data } = await axios.post(`${constant.floorplanSiteEP(site)}`, body);
  return data;
};

export const putFloorplan = async (
  site: string,
  floorplan: string,
  body: any
) => {
  await setAuthToken();
  const { data } = await axios.put(
    `${constant.putFloorplanSiteEP(site, floorplan)}`,
    body
  );
  return data;
};

export const deleteFloorplan = async (floorplan: string) => {
  await setAuthToken();
  const { data } = await axios.delete(
    `${constant.deleteFloorplanSiteEP(floorplan)}`
  );
  return data;
};

// Interlocks
export const getSiteInterlocks = async (site: string) => {
  await setAuthToken();
  const { data } = await axios.get(constant.getSiteInterlocksEP(site));
  return data;
};

export const getInterlockAvailableDoors = async (site: string, interlocks = '') => {
  await setAuthToken();
  const { data } = await axios.get(
    constant.getInterlocksAvailableDoorsEP(site, interlocks)
  );
  return data;
};

export const postInterlock = async (site: string, body: any) => {
  await setAuthToken();
  const { data } = await axios.post(constant.postInterlockEP(site), body);
  return data;
};

export const deleteInterlock = async (interlock: string) => {
  await setAuthToken();
  const { data } = await axios.delete(constant.deleteInterlockEP(interlock));
  return data;
}

export const putInterlock = async (interlock: string, body: any) => {
  await setAuthToken();
  const { data } = await axios.put(constant.putInterlockEP(interlock), body);
  return data;
};

export const getInterlock = async (interlock: string) => {
  await setAuthToken();
  const { data } = await axios.get(constant.getInterlockEP(interlock));
  return data;
}

// Support Tool
export const getOpenSessions = async (filter: string = '', support_type: string = 'all') => {
  await setAuthToken();
  const { data } = await axios.get(`${constant.supportGeneralEP}?filter=${filter}&support_type=${support_type}`);
  return data;
}

export const getSupportAccountsList = async (filter: string = '') => {
  await setAuthToken();
  const { data } = await axios.get(`${constant.supportAccountListEP}?filter=${filter}`);
  return data;
}

export const getSupportDealerUserList = async (dealer: string, filter: string = '') => {
  await setAuthToken();
  const { data } = await axios.get(`${constant.supportDealerUserListEP(dealer)}?filter=${filter}`);
  return data;
}

export const supportSendOtp = async (body: object) => {
  await setAuthToken();
  const { data } = await axios.post(constant.supportSendOtpEP, body);
  return data;
};

export const supportVerifyOtp = async (body: object) => {
  await setAuthToken();
  const { data } = await axios.post(constant.supportGeneralEP, body);
  return data;
}

export const supportOpenSession = async (session: string, body?: { session_code: string }) => {
  await setAuthToken();
  const { data } = await axios.post(constant.supportSessionEP(session), body);
  return data;
};

export const supportEndSession = async (session: string) => {
  await setAuthToken();
  const { data } = await axios.delete(constant.supportSessionEP(session));
  return data;
}

export const supportVerifySessionCode = async (session: string, body: object) => {
  await setAuthToken();
  const { data } = await axios.post(constant.supportSessionVerifyEP(session), body);
  return data;
}

export const getSupportControllersList = async ({
  filter = '',
  serial_number = '',
  status = '',
  organization = ''
}) => {
  await setAuthToken();
  const { data } = await axios.get(
    `${constant.supportControllersEP}?filter=${filter}&serial_number=${serial_number}&status=${status}&organization=${organization}`
  );
  return data;
};

export const getSupportControllerModules = async (controller: string) => {
  await setAuthToken();
  const { data } = await axios.get(constant.supportControllerModulesEP(controller));
  return data;
};

export const getSupportControllerStatus = async (controller: string) => {
  await setAuthToken();
  const { data } = await axios.get(constant.supportControllerStatusEP(controller));
  return data;
}

export const supportPingController = async (controller: string) => {
  await setAuthToken();
  const { data } = await axios.post(constant.supportControllerPingEP(controller));
  return data;
}

export const getSupportEntities = async () => {
  await setAuthToken();
  const { data } = await axios.get(constant.supportEntitiesEP);
  return data;
}

export const getSupportControllerEntities = async (controller: string) => {
  await setAuthToken();
  const { data } = await axios.get(constant.supportControllerEntitiesEP(controller));
  return data;
}

export const putSupportContactNumber = async (number: string) => {
  await setAuthToken();
  const body = {contact_number: number}
  const { data } = await axios.put(constant.supportContactNumberEP, body);
  return data;
}

export const supportSendControllerCommand = async (controller: string, entityType: string, body: object) => {
  await setAuthToken();
  const { data } = await axios.post(constant.supportControllerSdkEP(controller, entityType), body);
  return data;
}

export const getSupportSdkEntitiesBasicInfo = async (controller: string, entityType: string) => {
  await setAuthToken();
  const { data } = await axios.get(constant.supportControllerSdkEP(controller, entityType));
  return data;
}

export const postSupportOrganization = async (organization: string) => {
  await setAuthToken();
  const body = {organization: organization}
  const { data } = await axios.post(constant.supportSetOrganizationEP, body);
  return data;
}

export const getSupportCommandTasks = async (params: {
  organization: string,
  controller?: string,
  status?: string,
  serial_number?: string,
  source?: string,
  entity_type?: number,
  created_at?: string,
  command_class?: string,
  finished_at?: string,
  last_alive_at?: string,
  entity_uuid?: string,
}) => {
  await setAuthToken();

  const queryParams = Object.entries(params)
    .filter(([_, value]) => value !== undefined) // Exclude undefined values
    .map(([key, value]) => `${encodeURIComponent(key)}=${encodeURIComponent(value)}`)
    .join('&');

  const { data } = await axios.get(`${constant.supportCommandTasksEP}?${queryParams}`);
  return data;
}

export const getSupportCommandTaskDetails = async (source: string, cmd_header: string, cmd_detail: string) => {
  await setAuthToken();
  const { data } = await axios.get(constant.supportCommandTaskDetailsEP(source, cmd_header, cmd_detail));
  return data;
}

export const supportStopTask = async (source: string, cmd_header: string, cmd_detail: string) => {
  await setAuthToken();
  const { data } = await axios.delete(constant.supportCommandTaskDetailsEP(source, cmd_header, cmd_detail));
  return data;
}

export const getSupportSdkEntities = async (params: {
  entity_type: number;
  site: string;
  group_by: 'r' | 'c';
  from?: string;
  to?: string;
  offset?: number;
  limit?: number;
  filter?: string;
}) => {
  await setAuthToken();

  const { entity_type, site, ...rest } = params;

  const queryParams = Object.entries(rest)
    .filter(([_, value]) => value !== undefined) // Exclude undefined values
    .map(([key, value]) => `${encodeURIComponent(key)}=${encodeURIComponent(value)}`)
    .join('&');

  const { data } = await axios.get(`${constant.supportSdkEntitiesEP(entity_type, site)}?${queryParams}`);
  return data;
}

export const getSupportSdkEntitiesDetails = async ({
  entity_type,
  site,
  entity_cloud_id
}: {
  entity_type: number;
  site: string;
  entity_cloud_id: string;
}) => {
  await setAuthToken();

  const { data } = await axios.get(constant.supportSdkEntitiesDetailsEP(entity_type, site, entity_cloud_id));
  return data;
}

export const supportPushSdkEntitiesDetails = async ({
  entity_type,
  site,
  entity_cloud_id,
  body
}: {
  entity_type: number;
  site: string;
  entity_cloud_id: string;
  body: any;
}) => {
  await setAuthToken();
  const { data } = await axios.put(constant.supportSdkEntitiesDetailsEP(entity_type, site, entity_cloud_id), body);
  return data;
}

//Diagnosis Tool

export const postDiagnosisTool = async (serial_number: string) => {
  const body = {serial_number: serial_number}
  await setAuthToken();
  const { data } = await axios.post(constant.diagnosisToolEP, body);
  return data;
}

export const postDiagnosisToolPing = async (serial_number: string) => {
  const body = {serial_number: serial_number}
  await setAuthToken();
  const { data } = await axios.post(constant.diagnosisToolPingEP, body);
  return data;
}

export const postDiagnosisRestoreGlobalCert = async (serial_number: string) => {
  const body = {serial_number: serial_number}
  await setAuthToken();
  const { data } = await axios.post(constant.diagnosisToolRestoreGlobalCertEP, body);
  return data;
}

export const postDiagnosisRegisterController = async (serial_number: string) => {
  const body = {serial_number: serial_number}
  await setAuthToken();
  const { data } = await axios.post(constant.diagnosisToolRegisterControllerEP, body);
  return data;
}

export const getFrontendStatus = async () => {
  await setAuthToken();
  const { data } = await axios.get(constant.frontendStatusEP);
  return data;
}

export const getBackendStatus = async () => {
  await setAuthToken();
  const { data } = await axios.get(constant.backendStatusEP);
  return data;
}

export const getDatabaseStatus = async () => {
  await setAuthToken();
  const { data } = await axios.get(constant.databaseStatusEP);
  return data;
}

export const getAwsIotStatus = async () => {
  await setAuthToken();
  const { data } = await axios.get(constant.AwsIotStatusEP);
  return data;
}
