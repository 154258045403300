// Interfaces

import { site } from 'interfaces/site.interface';

// Actions
export const SET_SITE = 'SET_SITE';

// Action creators
export const setSelectedSite = (site: site | undefined) => ({
  type: SET_SITE,
  site: site
});
