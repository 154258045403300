import { Outlet } from 'react-router-dom';

import { Box } from '@mui/material';

const PublicLayout = () => {
  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: '#eff2f5',
        minHeight: '100vh',
        padding: 4,
      }}
    >
      <Outlet />
    </Box>
  );
};

export default PublicLayout;
