import { Box, CircularProgress } from '@mui/material';

import logo_cdvi from 'assets/images/media/logos/CDVI_CLOUD4.png'

const SplashScreen = () => {
  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',

        gap: 1,
        height: '100vh',
        flexDirection: 'column',
      }}
    >
      <img src={logo_cdvi} alt="CDVI" width={260} height={146} />

      <CircularProgress size={75} />
    </Box>
  )
}

export default SplashScreen
